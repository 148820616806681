import React from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import NavBar from './content/components/NavBar';
import HomePage from './pages/HomePage';
import AteliersPage from './pages/AteliersPage';
import AccompagnementsPage from './pages/AccompagnementsPage';
import QuiSuisJePage from './pages/QuiSuisJePage';
import ConceptsDeGestaltPage from './pages/ConceptsDeGestalt';
import RessourcesPage from './pages/Ressources';
import TarifsPage from './pages/TarifsPage';
import ContactezMoiPage from './pages/ContactezMoi';

const App: React.FC = () => {
  return (
    <Router>
      <div>
        <NavBar />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/ateliers" element={<AteliersPage />} />
          <Route path="/accompagnements" element={<AccompagnementsPage />} />
          <Route path="/qui-suis-je" element={<QuiSuisJePage />} />
          <Route path="/gestalt" element={<ConceptsDeGestaltPage />} />
          <Route path="/ressources" element={<RessourcesPage />} />
          <Route path="/tarifs" element={<TarifsPage />} />
          <Route path="/contact" element={<ContactezMoiPage />} />
        </Routes>
      </div>
    </Router>
  );
};

export default App;
