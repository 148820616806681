import Article from "../content/components/Article";
import Header from "../content/components/Header";
import LinkButton from "../content/components/LinkButton";
import AtelierText from "../textes/AteliersText";
import '../TextStyle.css';

const photo1_1 = require('../assets/AtelierPage/Atelier 1 Photo Gauche.jpg');
const photo1_2 = require('../assets/AtelierPage/Atelier 1 Photo Droite.jpg');
const photo2_1 = require('../assets/AtelierPage/Atelier 2 Photo Gauche.jpg');
const photo2_2 = require('../assets/AtelierPage/Atelier 2 Photo Droite.jpg');
const photo3_1 = require('../assets/AtelierPage/Atelier 3 Photo Gauche.jpg');
const photo3_2 = require('../assets/AtelierPage/Atelier 3 Photo Droite.jpg');
const photo4_1 = require('../assets/AtelierPage/Atelier 4 Photo Gauche.jpg');
const photo4_2 = require('../assets/AtelierPage/Atelier 4 Photo Droite.jpg');
const photo5_1 = require('../assets/AtelierPage/Atelier 5 Photo Gauche.jpg');
const photo5_2 = require('../assets/AtelierPage/Atelier 5 Photo Droite.jpg');
const photo6_1 = require('../assets/AtelierPage/Atelier 6 Photo Gauche.jpg');
const photo6_2 = require('../assets/AtelierPage/Atelier 6 Photo Droite.jpg');
const photo7_1 = require('../assets/AtelierPage/Atelier 7 Photo Gauche.jpg');
const photo7_2 = require('../assets/AtelierPage/Atelier 7 Photo Droite.jpg');
const photo8_1 = require('../assets/AtelierPage/Atelier 8 Photo Gauche.jpg');
const photo8_2 = require('../assets/AtelierPage/Atelier 8 Photo Droite.jpg');
const photo9_1 = require('../assets/AtelierPage/Atelier 9 Photo Gauche.jpg');
const photo9_2 = require('../assets/AtelierPage/Atelier 9 Photo Droite.jpg');
const photo10_1 = require('../assets/AtelierPage/Atelier 10 Photo Gauche.jpg');
const photo10_2 = require('../assets/AtelierPage/Atelier 10 Photo Droite.jpg');
const photo11_1 = require('../assets/AtelierPage/Atelier 11 Photo Gauche.jpg');
const photo11_2 = require('../assets/AtelierPage/Atelier 11 Photo Droite.jpg');
const photoOutro = require('../assets/AtelierPage/Outro Photo.jpg');



function AteliersPage() {
    return <div style={{width: "100%"}}>
            <Header title="Les Ateliers Juste Parent 44" subtitle={AtelierText.paragraphe1}/>
            {/* Section 1 */}
            <Article color="#DDE0DB">
                <div className="flex flex-col items-center spacing">
                    <h2 className="text-center">{AtelierText.titre2}</h2>
                    <div className="flex flex-row items-center spacing">
                        <img src={photo1_1} className="w-1/3 rounded" alt='tkt chef'/>
                        <div className="flex flex-col items-start spacing">
                            <h5 className="text-left">{AtelierText.paragraphe2}</h5>
                            <img src={photo1_2} className="w-1/2 rounded" alt='tkt chef'/>
                        </div>
                    </div>
                    <h4 className="text-center">{AtelierText.atelier2}</h4>
                </div>
            </Article>
            {/* Section 2 */}
            <Article color="#FBF6F1">
                <div className="flex flex-col items-center spacing">
                    <h2 className="text-center">{AtelierText.titre3}</h2>
                    <div className="flex flex-row items-center spacing">
                        <div className="flex flex-col items-end spacing">
                            <h5 className="text-right">{AtelierText.paragraphe3}</h5>
                            <img src={photo2_1} className="w-1/2 rounded" alt='tkt chef'/>
                        </div>
                        <img src={photo2_2} className="w-1/3 rounded" alt='tkt chef'/>
                    </div>
                    <h4 className="text-center">{AtelierText.atelier3}</h4>
                </div>
            </Article>
            {/* Section 3 */}
            <Article color="#F7EDDE">
                <div className="flex flex-col items-center spacing">
                    <h2 className="text-center">{AtelierText.titre4}</h2>
                    <div className="flex flex-row items-center spacing">
                        <img src={photo3_1} className="w-1/3 rounded" alt='tkt chef'/>
                        <div className="flex flex-col items-start spacing">
                            <h5 className="text-left">{AtelierText.paragraphe4}</h5>
                            <img src={photo3_2} className="w-1/2 rounded" alt='tkt chef'/>
                        </div>
                    </div>
                    <h4 className="text-center">{AtelierText.atelier4}</h4>
                </div>
            </Article>
            {/* Section 4 */}
            <Article color="#FBF6F1">
                <div className="flex flex-col items-center spacing">
                    <h2 className="text-center">{AtelierText.titre5}</h2>
                    <div className="flex flex-row items-center spacing">
                        <div className="flex flex-col items-end spacing">
                            <h5 className="text-right">{AtelierText.paragraphe5}</h5>
                            <img src={photo4_1} className="w-1/2 rounded" alt='tkt chef'/>
                        </div>
                        <img src={photo4_2} className="w-1/3 rounded" alt='tkt chef'/>
                    </div>
                    <h4 className="text-center">{AtelierText.atelier5}</h4>
                </div>
            </Article>
            {/* Section 5 */}
            <Article color="#F3E8E8">
                <div className="flex flex-col items-center spacing">
                    <h2 className="text-center">{AtelierText.titre6}</h2>
                    <div className="flex flex-row items-center spacing">
                        <img src={photo5_1} className="w-1/3 rounded" alt='tkt chef'/>
                        <div className="flex flex-col items-start spacing">
                            <h5 className="text-left">{AtelierText.paragraphe6}</h5>
                            <img src={photo5_2} className="w-1/2 rounded" alt='tkt chef'/>
                        </div>
                    </div>
                    <h4 className="text-center">{AtelierText.atelier6}</h4>
                </div>
            </Article>
            {/* Section 6 */}
            <Article color="#FBF6F1">
                <div className="flex flex-col items-center spacing">
                    <h2 className="text-center">{AtelierText.titre7}</h2>
                    <div className="flex flex-row items-center spacing">
                        <div className="flex flex-col items-end spacing">
                            <h5 className="text-right">{AtelierText.paragraphe7}</h5>
                            <img src={photo6_1} className="w-1/2 rounded" alt='tkt chef'/>
                        </div>
                        <img src={photo6_2} className="w-1/3 rounded" alt='tkt chef'/>
                    </div>
                    <h4 className="text-center">{AtelierText.atelier7}</h4>
                </div>
            </Article>
            {/* Section 7 */}
            <Article color="#E7EAE6">
                <div className="flex flex-col items-center spacing">
                    <h2 className="text-center">{AtelierText.titre8}</h2>
                    <div className="flex flex-row items-center spacing">
                        <img src={photo7_1} className="w-1/3 rounded" alt='tkt chef'/>
                        <div className="flex flex-col items-start spacing">
                            <h5 className="text-left">{AtelierText.paragraphe8}</h5>
                            <img src={photo7_2} className="w-1/2 rounded" alt='tkt chef'/>
                        </div>
                    </div>
                    <h4 className="text-center">{AtelierText.atelier8}</h4>
                </div>
            </Article>
            {/* Section 8 */}
            <Article color="#FBF6F1">
                <div className="flex flex-col items-center spacing">
                    <h2 className="text-center">{AtelierText.titre9}</h2>
                    <div className="flex flex-row items-center spacing">
                        <div className="flex flex-col items-end spacing">
                            <h5 className="text-right">{AtelierText.paragraphe9}</h5>
                            <img src={photo8_1} className="w-1/2 rounded" alt='tkt chef'/>
                        </div>
                        <img src={photo8_2} className="w-1/3 rounded" alt='tkt chef'/>
                    </div>
                    <h4 className="text-center">{AtelierText.atelier9}</h4>
                </div>
            </Article>
            {/* Section 9 */}
            <Article color="#F7EDDE">
                <div className="flex flex-col items-center spacing">
                    <h2 className="text-center">{AtelierText.titre10}</h2>
                    <div className="flex flex-row items-center spacing">
                        <img src={photo9_1} className="w-1/3 rounded" alt='tkt chef'/>
                        <div className="flex flex-col items-start spacing">
                            <h5 className="text-left">{AtelierText.paragraphe10}</h5>
                            <img src={photo9_2} className="w-1/2 rounded" alt='tkt chef'/>
                        </div>
                    </div>
                    <h4 className="text-center">{AtelierText.atelier10}</h4>
                </div>
            </Article>
            {/* Section 10 */}
            <Article color="#FBF6F1">
                <div className="flex flex-col items-center spacing">
                    <h2 className="text-center">{AtelierText.titre11}</h2>
                    <div className="flex flex-row items-center spacing">
                        <div className="flex flex-col items-end spacing">
                            <h5 className="text-right">{AtelierText.paragraphe11}</h5>
                            <img src={photo10_1} className="w-1/2 rounded" alt='tkt chef'/>
                        </div>
                        <img src={photo10_2} className="w-1/3 rounded" alt='tkt chef'/>
                    </div>
                    <h4 className="text-center">{AtelierText.atelier11}</h4>
                </div>
            </Article>
            {/* Section 11 */}
            <Article color="#FAE6DC">
                <div className="flex flex-col items-center spacing">
                    <h2 className="text-center">{AtelierText.titre12}</h2>
                    <div className="flex flex-row items-center spacing">
                        <img src={photo11_1} className="w-1/3 rounded" alt='tkt chef'/>
                        <div className="flex flex-col items-start spacing">
                            <h5 className="text-left">{AtelierText.paragraphe12}</h5>
                            <img src={photo11_2} className="w-1/2 rounded" alt='tkt chef'/>
                        </div>
                    </div>
                    <h4 className="text-center">{AtelierText.atelier12}</h4>
                </div>
            </Article>
            {/* Section 12 */}
            <Article color="#534F4F">
                <div className="flex flex-col items-center spacing">
                    <h2 className="text-center" style={{ color: "#EB977C" }}> {AtelierText.outroTitre} </h2>
                    <h3 className="text-center text-white">{AtelierText.outroSoustitre}</h3>
                    <img src={photoOutro} className="w-1/3 rounded" alt='tkt chef'/>
                    <h5 className="text-center text-white">{AtelierText.outroParagraphe}</h5>
                    <LinkButton text="Découvrez mes Accompagnements" path="/accompagnements" />
                </div>
            </Article>
        </div >
}

export default AteliersPage;