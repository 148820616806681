import Header from "../content/components/Header";
import LinkButton from "../content/components/LinkButton";
import TarifsText from "../textes/TarifsText";

import '../TextStyle.css';

function TarifsPage() {
    return <div>
        <Header title='Tarifs et informations pratiques' subtitle={TarifsText.intro} />
        <div className="flex flex-col pb-5 gap-10" style={{ background: "linear-gradient(90deg, rgba(221, 224, 219, 1) 0% 33%, rgba(247, 237, 222, 1) 33% 66%, rgba(243, 232, 232, 1) 66% 100%)" }}>
            {/* Ligne de départ */}
            <div className="flex flex-row">
                <div className="basis-[33%]"></div>
                <div className="basis-[67%] place-self-end" style={{ background: "#8D8080" }}>
                    <h5 className="text-center text-white">RÉSERVÉ aux personnes ayant participé aux ateliers Juste Parent.</h5>
                </div>
            </div>

            {/* Titre + subtitle */}
            <div className="flex flex-row">
                <div className="px-5 basis-1/3">
                    <h2 className="text-center">Les ateliers "Juste Parent"</h2>
                    <h5 className="text-center">en présentiel, à Nantes</h5>
                </div>
                <div className="px-5 basis-1/3">
                    <h2 className="text-center">Accompagnement de groupe</h2>
                    <h5 className="text-center">en présentiel, à Nantes</h5>
                </div>
                <div className="px-5 basis-1/3">
                    <h2 className="text-center">Accompagnement individuel</h2>
                    <h5 className="text-center">en distanciel</h5>
                </div>
            </div>
            {/* Contenu */}
            <div className="flex flex-row">
                <div className="flex flex-col gap-3 px-5 basis-1/3">
                    <h4 className="text-center">Contenu</h4>
                    <h5 className="text-center">Pack de 11 ateliers + 3 accompagnements de groupe.</h5>
                </div>
                <div className="flex flex-col gap-3 px-5 basis-1/3">
                    <h4 className="text-center">Contenu</h4>
                    <h5 className="text-center">1 séance d’accompagnement en groupe de 4 à 8 personnes.</h5>
                </div>
                <div className="flex flex-col gap-3 px-5 basis-1/3">
                    <h4 className="text-center">Contenu</h4>
                    <h5 className="text-center">1 séance d’accompagnement individuel ou en couple parental</h5>
                </div>
            </div>
            {/* Durée + premier texte*/}
            <div className="flex flex-row">
                <div className="flex flex-col gap-3 px-5 basis-1/3">
                    <h4 className="text-center">Durée</h4>
                    <h5 className="text-center">14 séances d’~ 2h30, sur 4 mois (environ 1 séance / semaine, hors vacances scolaires) = 35h de contenus</h5>
                </div>
                <div className="flex flex-col gap-3 px-5 basis-1/3">
                    <h4 className="text-center">Durée</h4>
                    <h5 className="text-center">Entre 1h et 2h30, en fonction du nombre de participants. <br />
                        Temps de partage : 15 minutes / pers.</h5>
                </div>
                <div className="flex flex-col gap-3 px-5 basis-1/3">
                    <h4 className="text-center">Durée</h4>
                    <h5 className="text-center">45 minutes.</h5>
                </div>
            </div>
            {/* Nombre de personnes */}
            <div className="flex flex-row items-center">
                <div className="px-5 basis-1/3">
                    <h5 className="text-center">L’engagement est pris pour les 4 mois afin de préserver la stabilité du  groupe et d’offrir à chacun.e un environnement sécurisant et soutenant.</h5>
                </div>
                <div className="px-5 basis-1/3">
                    <h5 className="text-center">Minimum 4 pers. / Maximum 8 pers.</h5>
                </div>
                <div className="px-5 basis-1/3">
                    <h5 className="text-center">Possibilité de venir en couple (parental).</h5>
                </div>
            </div>
            {/* Lieu */}
            <div className="flex flex-row">
                <div className="px-5 basis-1/3">
                    <h5 className="text-center"> <b>Lieu</b> : La Maison des Possibles, <br />
                        147 rue du Corps de Garde à Nantes.</h5>
                </div>
                <div className="px-5 basis-1/3">
                    <h5 className="text-center"><b>Lieu</b> : La Maison des Possibles, <br />
                    147 rue du Corps de Garde à Nantes. <br /><br />
                    ou en Distanciel</h5>
                </div>
                <div className="px-5 basis-1/3">
                    <h5 className="text-center"><b>Lieu</b> : La Maison des Possibles, <br />
                    147 rue du Corps de Garde à Nantes. <br /><br />
                    ou en Distanciel</h5>
                </div>
            </div>
            {/* Dates */}
            <div className="flex flex-row">
                <div className="px-5 basis-1/3">
                    <h5 className="text-center"><b>Dates au 1er semestre 2025 : </b><br />
                        de 17h30 à 20h00, <br />
                        les mardis (hors vacances scolaires) : <br />
                        25 février, <br />
                        04, 11, 18 & 25 mars, <br />
                        01, 22 & 29 avril, <br />
                        06, 13, & 20 mai, <br />
                        03, 10 & 17 juin.</h5>
                </div>
                <div className="px-5 basis-1/3 place-self-center">
                    <h5 className="text-center">Sur RDV</h5>
                </div>
                <div className="px-5 basis-1/3 place-self-center">
                    <h5 className="text-center">Sur RDV</h5>
                </div>
            </div>
            {/* Tarifs */}
            <div className="flex flex-row">
                <div className="flex flex-col gap-3 px-5 basis-1/3">
                    <h4 className="text-center">Tarifs</h4>
                    <h5 className="text-center">880€/pers.</h5>
                </div>
                <div className="flex flex-col gap-3 px-5 basis-1/3">
                    <h4 className="text-center">Tarifs</h4>
                    <h5 className="text-center">30€/pers.</h5>
                </div>
                <div className="flex flex-col gap-3 px-5 basis-1/3">
                    <h4 className="text-center">Tarifs</h4>
                    <h5 className="text-center">70€/pers. ou 85€/couple parental</h5>
                </div>
            </div>
            {/* Boutons */}
            <div className="flex flex-row">
                <div className="flex flex-col px-5 basis-1/3 items-center">
                    <LinkButton text="Je découvre les Ateliers" path="/ateliers" />
                </div>
                <div className="flex flex-col px-5 basis-2/3 items-center">
                    <LinkButton text="Je découvre les Accompagnements" path="/accompagnements" />
                </div>
            </div>
        </div>
        <div className="px-8 py-12 flex flex-col gap-5 items-center" style={{ backgroundColor: "#534F4F" }}>
            {/* Footer */}
            <h2 className="text-center" style={{ color: "#EB977C" }}>Mais encore ?</h2>
            <h2 className="pl-5 place-self-start" style={{ color: "#EB977C" }}>• des questions restées sans réponses ?</h2>
            <h2 className="pb-3" style={{ color: "#FFFFFF" }}>{TarifsText.footer1}</h2>
            <h2 className="pl-5 place-self-start" style={{ color: "#EB977C" }}>• envie de vous inscrire ?</h2>
            <h2 className="pb-3" style={{ color: "#FFFFFF" }}>{TarifsText.footer2}</h2>
            <LinkButton text="Contactez moi!" path="/contact" />
        </div>
    </div >
}

export default TarifsPage;
