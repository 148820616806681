const intro =
    "Tarifs pour le pack des 11 ateliers Juste Parent + 3 accompagnements de groupe\n" +
    "Tarifs des Accompagnements supplémentaires (en groupe ou individuels)";

const footer1 =
    "Certaines informations pratiques pourront vous être apportées.\n" +
    "Si vous vous posez des questions par rapport à votre situation personnelle ou si vous avez besoin de plus de précisions, laissez moi un message. Je vous répondrai avec plaisir.";

const footer2 =
    "Si vous êtes intéressé.e par les Ateliers “Juste Parent” et/ou mes Accompagnements, laissez moi un message en ce sens avec vos coordonnées téléphoniques.\n" +
    "Avant toute inscription, je vous proposerai un échange téléphonique (~ 20 min) afin que vous et moi soyons certain.e.s que ce choix est le plus adapté à vos besoins.";

export default { intro, footer1, footer2 };
