// import LinkButton from "../content/components/LinkButton";
import ConceptsDeGestaltText from "../textes/ConceptsDeGestaltText";
import LinkButton from "../content/components/LinkButton";
import '../TextStyle.css';
import Header from "../content/components/Header";
import Article from "../content/components/Article";

const photo1 = require('../assets/ConceptsDeGestaltPage/Concept 1.jpg');
const photo2 = require('../assets/ConceptsDeGestaltPage/Concept 2.jpg');
const photo3 = require('../assets/ConceptsDeGestaltPage/Concept 3.jpg');
const photo4 = require('../assets/ConceptsDeGestaltPage/Concept 4.jpg');
const photo5 = require('../assets/ConceptsDeGestaltPage/Concept 5.jpg');
const photo6 = require('../assets/ConceptsDeGestaltPage/Concept 6.jpg');
const photo7 = require('../assets/ConceptsDeGestaltPage/Concept 7.png');
const photo8 = require('../assets/ConceptsDeGestaltPage/Concept 8.jpg');
const photo9 = require('../assets/ConceptsDeGestaltPage/Concept 9.jpg');
const photo10 = require('../assets/ConceptsDeGestaltPage/Concept 10.jpg');
const photo11 = require('../assets/ConceptsDeGestaltPage/Concept 11.jpg');
const photo12 = require('../assets/ConceptsDeGestaltPage/Concept 12.png');


function ConceptsDeGestaltPage() {
    return <div style={{width: "100%"}}>
        <Header title="Concepts et outils de Gestalt" subtitle={ConceptsDeGestaltText.paragraphe1}/>
        {/* Section 1 */}
        <Article color="#DDE0DB">
            <div className="flex flex-col spacing">
                <h2 className="text-center">Ici et Maintenant et Page Blanche</h2>
                <h5>{ConceptsDeGestaltText.paragraphe2}</h5>
                <div className="grow flex flex-row spacing items-center">
                    <h5>{ConceptsDeGestaltText.paragraphe3}</h5>
                    <img src={photo1} className="w-1/3 rounded" alt='tkt chef' />
                </div>
                <h5>{ConceptsDeGestaltText.paragraphe4}</h5>
            </div>
        </Article>
        {/* Section 2 */}
        <Article color="#F3E8E8">
            <div className="flex flex-col spacing">
                <h2 className="text-center">Prise de Conscience</h2>
                <div className="grow flex flex-row spacing items-center">
                    <img src={photo2} className="w-1/3 rounded" alt='tkt chef' />
                    <h5>{ConceptsDeGestaltText.paragraphe5}</h5>
                </div>
            </div>
        </Article>
        {/* Section 3 */}
        <Article color="#F7EDDE">
            <div className="flex flex-col spacing">
                <h2 className="text-center">Intentionnalité</h2>
                <div className="grow flex flex-row spacing items-center">
                    <h5>{ConceptsDeGestaltText.paragraphe6}</h5>
                    <img src={photo3} className="w-80 h-80 rounded" alt='tkt chef' />
                </div>
                <h5>{ConceptsDeGestaltText.paragraphe7}</h5>
            </div>
        </Article>
        {/* Section 4 */}
        <Article color="#CAD0C8">
            <div className="grow flex flex-col spacing">
                <div className="flex flex-row spacing items-center">
                    <img src={photo4} className="w-1/3 rounded" alt='tkt chef' />
                    <div className="grow flex flex-col spacing">
                        <h2 className="text-center">Figure et Fond</h2>
                        <h5>{ConceptsDeGestaltText.paragraphe8}</h5>
                    </div>
                </div>
                <h5>{ConceptsDeGestaltText.paragraphe9}</h5>
            </div>
        </Article>
        {/* Section 5 */}
        <Article color="#F2DFD9">
            <div className="flex flex-col spacing">
                <h2 className="text-center">Responsabilité</h2>
                <div className="flex flex-row spacing items-center">
                    <h5>{ConceptsDeGestaltText.paragraphe10}</h5>
                    <img src={photo5} className="w-1/3 rounded" alt='tkt chef' />
                </div>
                <h5>{ConceptsDeGestaltText.paragraphe11}</h5>
            </div>
        </Article>
        {/* Section 6 */}
        <Article color="#F3E6D8">
            <div className="flex flex-col spacing">
                <h2 className="text-center">Choix et Liberté</h2>
                <div className="grow flex flex-row spacing items-center">
                    <img src={photo6} className="w-1/3 rounded" alt='tkt chef' />
                    <h5>{ConceptsDeGestaltText.paragraphe12}</h5>
                </div>
            <h5>{ConceptsDeGestaltText.paragraphe13}</h5>
            </div>
        </Article>
        {/* Section 7 */}
        <Article color="#E7EAE6">
            <div className="flex flex-col spacing">
                <h2 className="text-center">Zones d’influence</h2>
                <h5>{ConceptsDeGestaltText.paragraphe14}</h5>
                <img src={photo7} className="w-100% rounded" alt='tkt chef' />
                <h5>{ConceptsDeGestaltText.paragraphe15}</h5>
            </div>
        </Article>
        {/* Section 8 */}
        <Article color="#EFDCD4">
            <div className="flex flex-col spacing">
                <h2 className="text-center">Lâcher-prise</h2>
                <div className="grow flex flex-row spacing items-center">
                    <h5>{ConceptsDeGestaltText.paragraphe16}</h5>
                    <img src={photo8} className="w-1/3 rounded" alt='tkt chef' />
                </div>
            </div>
        </Article>
        {/* Section 9 */}
        <Article color="#EDDCC2">
            <div className="flex flex-row spacing items-center">
                <img src={photo9} className="w-1/3 rounded" alt='tkt chef' />
                <div className="grow flex flex-col spacing">
                    <h2 className="text-center">Frontière Contact</h2>
                    <h5>{ConceptsDeGestaltText.paragraphe17}</h5>
                </div>
            </div>
        </Article>
        {/* Section 10 */}
        <Article color="#D3DFDA">
            <div className="flex flex-col spacing">
                <div className="grow flex flex-row spacing items-center">
                    <div className="flex flex-col spacing">
                        <h2 className="text-center">Accueil et Non-jugement</h2>
                        <h5>{ConceptsDeGestaltText.paragraphe18}</h5>
                    </div>
                    <img src={photo10} className="w-1/3 rounded" alt='tkt chef' />
                </div>
                <h5>{ConceptsDeGestaltText.paragraphe19}</h5>
            </div>
        </Article>
        {/* Section 11 */}
        <Article color="#F2DFD9">
            <div className="flex flex-col spacing">
                <h2 className="text-center">Acceptation</h2>
                <h5>“Accepte ce qui est, laisse aller ce qui était, et aie confiance en ce qui sera” (Bouddha)</h5>
                <div className="grow flex flex-row spacing items-center">
                    <img src={photo11} className="w-1/3 rounded" alt='tkt chef' />
                    <h5>{ConceptsDeGestaltText.paragraphe20}</h5>
                </div>
                <h5>{ConceptsDeGestaltText.paragraphe21}</h5>
                <h5>{ConceptsDeGestaltText.paragraphe22}</h5>
                <h5>{ConceptsDeGestaltText.paragraphe23}</h5>
            </div>
        </Article>
        {/* Section 12 */}
        <Article color="#F7EDDE">
            <div className="flex flex-col spacing">
                <h2 className="text-center">Cycle du contact de Zincker</h2>
                <div className="grow flex flex-row spacing items-center">
                    <h5>{ConceptsDeGestaltText.paragraphe24}</h5>
                    <img src={photo12} className="white-to-transparent w-1/3 rounded" alt='tkt chef' />
                </div>
            </div>
            <h5>{ConceptsDeGestaltText.paragraphe25}</h5>
        </Article>
        {/* Section 13 */}
        <Article color="#534F4F">
            <div className="flex flex-col spacing">
                <h2 className="text-center" style={{ color: "#EB977C" }}>Mais encore ?</h2>
                <h5 className="text-white">{ConceptsDeGestaltText.paragraphe26}</h5>
                <div className="flex flex-col spacing items-center">
                    <LinkButton text="Découvrez mes Accompagnements" path="/accompagnements" />
                </div>
            </div>
        </Article>
    </div>
}

export default ConceptsDeGestaltPage;