const paragraphe1 =
    "Les concepts et outils de la Gestalt sont riches, profonds et puissants.\n" +
    "On n’a jamais fini de les découvrir : \n" +
    "à chaque fois qu’on les aborde, on peut en comprendre une nouvelle facette, à un autre niveau, en fonction de là où on en est dans sa vie. \n" +
    "Il n’est donc pas nécessaire de tout cerner dès la 1ère lecture ;)";

const paragraphe2 =
    "L’ Ici et Maintenant (“ I & M ”) est un concept fondamental de la Gestalt et de la CNV (Communication NonViolente).\n\n" +
    "Pourquoi ?\n" +
    "Parce que c’est dans l’ I & M que nous avons tout notre pouvoir de communiquer et d’agir sur notre vie : nous ne pouvons être et agir que dans le présent (Maintenant), depuis là où nous sommes (Ici). \n\n" +
    "Le passé est terminé et n’est pas modifiable, le futur n’existe pas encore. Seuls l'Ici et le Maintenant existent réellement pour nous :\n" +
    "• je ne suis plus dans le passé et pas encore dans l'avenir,\n" +
    "• je suis physiquement ici et pas ailleurs.";

const paragraphe3 = //go aligner l'image à ce paragraphe
    "Si mon esprit est ailleurs, je ne peux pas réellement et entièrement faire l'expérience de ce qu'il se passe Ici & Maintenant, car mon esprit, avec mes pensées sur le passé, le futur ou l'ailleurs, va teinter ce qu'il se passe, le modifier, le fausser, le déformer, le contaminer.\n\n" +
    "Or j'ai besoin d'être dans la conscience de ce qu'il se passe réellement dans l'Ici & Maintenant, sans l'influence ni la distorsion de mon esprit et de mes pensées liées au passé, au futur ou à l'ailleurs, pour :\n" +
    "• vivre pleinement mes expériences, mes rencontres, mes interactions 	avec les autres et les environnements dans lesquels je suis,\n" +
    "• être juste dans mes échanges, mon écoute, ma parole (sans interprétation, distorsion, imagination),\n" +
    "• rester connecté à mon interne et être authentique,\n" +
    "• faire des choix justes pour l'avenir,\n" +
    "• donner et recevoir, ressentir la gratitude...";

const paragraphe4 =
    "La Page Blanche permet de ne pas être influencé et envahi par notre histoire (blessures, expériences, croyances, regrets, peurs...). Elle sert à vivre des expériences librement, sans idées préconçues, sans nos entraves issues de notre passé et sans lunettes déformantes. C'est un outil de Gestalt qui aide vraiment à rester dans l'Ici & Maintenant.\n\n" +
    "Faire Page Blanche, c'est un peu comme faire un 'Reset' partiel et momentané, pour faire de la place pour ce qui vient dans l'Ici & Maintenant. C’est mettre nos pensées, jugements, croyances... de côté pour un temps, s’ouvrir à ce qui se présente dans l’ I & M, accueillir l’autre ou l’expérience avec un regard neuf, afin de le/la voir vraiment tel/le qu’il/elle se présente, sans lunettes déformantes.\n\n" +
    "“Une coupe n'est utile que si elle est vide” (Krishnamurti). \n" +
    "La Page Blanche c'est faire le vide Ici & Maintenant, pour mieux accueillir sans jugement, pour pouvoir accepter ce qui est, et alors seulement, pouvoir, en conscience, transformer ce que l’on veut changer, agir efficacement sur notre vie.\n\n" +
    "Quand je suis dans l' Ici & Maintenant, je suis pleinement conscient et connecté à mon interne (mon corps, mon cœur et ma tête) et à mon environnement dans l'instant présent. C’est à partir de cela que je peux agir sur ma vie de façon ajustée.";

const paragraphe5 = //bloc prise de conscience
    "La prise de conscience est le préalable à tout changement.\n" +
    "Comment pourrions nous décider de changer quelque chose dont nous n'avons pas conscience, c'est-à-dire qui n'existe pas pour nous ou qui n'est pas défini ? C'est pourquoi elle est si importante quand on veut changer une situation. Elle représente le premier pas vers ce changement.\n\n" +
    "C'est la lumière qui s'allume tout à coup en nous et qui éclaire ce qui était auparavant invisible, qui permet de prendre du recul, d’amener de la perspective.\n" +
    "La prise de conscience nous offre une liberté que l’on n’avait pas avant, celle de choisir nos ajustements : est-ce que je veux continuer comme avant ou est-ce que je désire apprendre à faire autrement ? Avant, on pensait qu’on n’avait pas le choix et on ne se sentait pas vraiment libre.\n\n" +
    "La prise de conscience est surtout une compréhension émotionnelle. Elle se traduit par un déclic interne, un mouvement intérieur qui va produire ensuite d'autres mouvements tels que des pensées, des émotions, des intentions et des actions.\n" +
    "Quand on a pris conscience de quelque chose, on est déjà différent, sans avoir besoin de rien faire, quelque chose a déjà changé en nous : on n’est déjà plus comme avant, quand on ne savait pas.\n\n" +
    "La prise de conscience est un mouvement interne (un début de changement intérieur) sans avoir à FAIRE quoi que soit. C'est toujours la 1ère étape indispensable et préalable à tout changement concret dans notre vie. Les ateliers “Juste Parent” permettent d’avoir des prises de conscience.";

const paragraphe6 = //image avec ce bloc
    "L’intentionnalité clarifie et verbalise ce que l'on veut créer dans sa vie parce qu’elle agit sur nos pensées, et nos pensées créent notre réalité (c’est en lien avec notre responsabilité).\n" +
    "Elle agit donc sur le pouvoir créateur de nos pensées et nous éloigne de nos croyances limitantes.\n\n" +
    "L'intentionnalité a un pouvoir très fort : telle un gouvernail, elle impulse une direction à notre esprit, notre corps, notre être tout entier (notre conscient et notre inconscient) car elle œuvre au niveau de l'émotionnel et agit consciemment et inconsciemment.\n\n" +
    "Poser une intention nous engage. Quand nous posons une intention, nous émettons un message à nous-même et à l'univers, nous ouvrons le champ des possibles, nous ouvrons notre esprit, notre regard, nos pensées et notre attention à de nouvelles possibilités qui vont nous aider dans la réalisation de notre intention (les fameuses coïncidences ou synchronicités).\n" +
    "C'est émotionnel et vibratoire. Et bien sûr, tout cela va nous aider à passer à l'action pour la réaliser et à finalement choisir réellement notre vie.\n\n" +
    "L'intention sans tension : c'est aussi accepter de se tromper sans se juger, accepter qu'il y ait des écarts ou des détours à faire, et même dans la tempête, continuer de maintenir le cap avec souplesse et confiance.";

const paragraphe7 =
    "Il est important de poser une intention à l'affirmative car le cerveau retient le sens et l'énergie des mots mais oublie les négations.\n\n" +
    "Poser son intention, c'est déjà un engagement (de soi à soi) et c'est puissant.";

const paragraphe8 = //image avec ce bloc
    "C'est un outil complémentaire de l'Ici & Maintenant et de la Page Blanche. Ce serait comme une 1ère étape avant la Page Blanche où l'on laisserait tout dans le Fond et ne mettrait rien en figure.\n" +
    "Et c'est lié à un choix que nous faisons dans l'Ici & Maintenant et qui change constamment d'Ici & Maintenant en Ici & Maintenant.\n\n" +
    "Comme le photographe, on décide de faire la mise au point (netteté) sur un élément central de notre photo, laissant le fond flou : ce qui est dans le flou est toujours là, mais n'est pas l'élément central de la photo qui est lui, mis en figure.\n\n" +
    "Qu'est-ce qu'on choisit de mettre en figure dans notre vie, dans l'Ici & Maintenant, et qu'est-ce qu'on décide de mettre dans le fond ?\n\n" +
    "C'est très en lien avec nos priorités, nos choix et notre responsabilité.";

const paragraphe9 = "C'est aussi en lien direct avec notre posture d'écoute active (que l’on aborde dans les ateliers “Juste Parent”) car dans ces moments-là, face à nos enfants débordants de telle ou telle émotion, nous pouvons être nous-même activé par des émotions fortes. Alors, quand nous nous sentons disponible et capable de le faire, nous pouvons, provisoirement, mettre nos propres activations émotionnelles dans le fond, pour laisser en figure celles de notre enfant que nous souhaitons écouter et réguler. Nous pourrons nous occuper de nos propres émotions dans un second temps.";

const paragraphe10 =
    "Le concept de responsabilité est très en lien avec d'autres tels que la prise de conscience, le choix, le non-jugement et l'acceptation et avec l’outil des Zones d’influence.\n\n" +
    "«L'important n'est pas ce que l'on a fait de nous, mais ce que nous faisons de ce que l'on a fait de nous» disait Sartre.\n\n" +
    "En ce sens, je suis responsable de l'adulte que je suis Ici & Maintenant parce que :\n" +
    "• je suis le/la seul.e à pouvoir faire un travail sur moi-même pour apprendre à me 	connaître et mettre de la conscience sur qui je suis et ce que je 	veux pour moi,\n" +
    "• je suis le/la seul.e à pouvoir agir sur moi-même, me transformer ou pas, décider où je veux aller,\n" +
    "• je ne suis plus dans la dépendance absolue de mes parents ou d'autres adultes pour survivre, pour subvenir à mes besoins élémentaires.";

const paragraphe11 =
    "Dans l'enfance, je me suis construit.e comme j'ai pu, avec les gènes que j'ai hérités et dans l'environnement qui était le mien. Je ne suis pas coupable de cela.\n" +
    "J'en deviens responsable à l'âge adulte, c'est-à-dire que j'en ai la charge : c'est ma vie, mon corps, mes pensées, mes émotions, mes besoins, mes limites, mes croyances, mes peurs, mes espoirs... \n" +
    "• tout cela m'appartient et parle de moi, de qui je suis, de comment je me suis construit.e. Je n'ai pas à en avoir honte, ni à me sentir fautif.ve.\n" +
    "• tout cela influence aussi ma vie, la façon dont j'interprète mes expériences de vie, dont je suis en relation avec les autres, dont je communique... On voit bien que dans une même situation, chacun a des réactions, des interprétations et des pensées qui lui sont propres. On pourrait dire que chacun a des lunettes avec des verres filtrants spécifiques et uniques.\n\n" +
    "L'externe s'aligne avec l'interne : ce que j'expérimente à l'extérieur est le reflet de ce que je ressens à l'intérieur : si je ne m'aime pas et ne me respecte pas, les autres ne vont sans doute pas beaucoup m'aimer et me respecter, si je suis persuadé d'être nul, je vais échouer dans ce que j'entreprends.\n\n" +
    "Si nous voulons retrouver du pouvoir sur notre vie, nous aurons d'abord à accepter notre responsabilité et nous débarrasser de la culpabilité qui nous ronge, nous plombe, nous décourage, nous paralyse et nous victimise. La culpabilité vient de notre jugement.\n" +
    "La responsabilité, au contraire de la culpabilité et du jugement, ouvre au changement, au champ des possibles, redonne le pouvoir d'agir sur sa vie et permet de sortir de la résignation et la victimisation.\n\n" +
    "Si nous voulons assumer notre responsabilité de façon constructive et créative, nous avons besoin d'apprendre à nous connaître, à nous accepter et à être bienveillant envers nous-même. Quand nous sommes capables d’accepter notre responsabilité, nous pouvons réparer : le mal que l’on se fait, le mal que l’on fait aux autres. La réparation nous allège, nous dynamise, nous encourage, nous redonne de la confiance.\n\n" +
    "La différence de perception émotionnelle entre responsabilité et culpabilité vient de notre bienveillance (respect, amour) envers nous-même, de l'acceptation de nous-même tel.le que nous sommes Ici & Maintenant et de la confiance que nous avons en nous-même d'être créateur de notre vie.\n\n" +
    "Au cours des ateliers “Juste Parent” nous aborderons le sujet de la responsabilité sous différents angles.\n" +
    "Par exemple :\n" +
    "• si nous ne voulons pas nous laisser agir par nos émotions débordantes (agressivité, cris, claques, mots durs...puis regrets),\n" +
    "• si nous voulons être entendu.e par nos enfants, susciter leur coopération et les accompagner vers l’autonomie,\n" +
    "nous aurons besoin d’apprendre à nous réguler émotionnellement,\n" +
    "nous devrons reconnaître nos émotions et les nommer,\n" +
    "nous devrons communiquer ce que nous ressentons de façon à la fois authentique et bienveillante (CNV).\n\n" +
    "Tout cela ne peut pas se faire si nous n’acceptons pas la responsabilité de nos émotions, car nous irions alors dans le blâme et l’accusation (ce qui coupe la communication), et nous ne serions pas entendus par nos enfants.\n\n" +
    "Nous sommes en quelque sorte doublement responsables de nos émotions : responsables parce qu’elles viennent de nous et parlent de notre interne, et responsables de ce que nous en faisons (agression ou communication authentique, respectueuse et non violente).\n\n" +
    "Ressentir notre responsabilité nous ouvre au changement et nous redonne le pouvoir d'agir sur notre vie et de sortir de la posture de victime résignée. Elle nous permet de faire des choix en étant conscient de nos vrais besoins pour transformer ce qui ne nous convient plus.";

const paragraphe12 =
    "A-t-on toujours le choix ?\n" +
    "En Gestalt, on pense que l’on a toujours un choix possible : celui de nos réactions et pensées face à un événement ou dans une relation avec un être ou un environnement, ce que nous décidons de faire de nos émotions...\n\n" +
    "On a le choix par exemple d’utiliser des outils à notre disposition pour incarner des concepts qui fondent nos valeurs : la “Page Blanche”, La “Figure et Fond”, les “Zones d’Influence”, la “Régulation Émotionnelle”, la respiration, la prise de conscience, l’accueil et l’acceptation, la CNV, la responsabilité, la demande d’aide, la formation, la thérapie... Si l’on ne choisit rien, c’est un choix quand même : celui de subir la réaction primaire de notre cerveau reptilien.\n\n" +
    "Que vais-je choisir aujourd’hui ? Que puis-je choisir pour que demain soit différent ? Qu’est-ce que j’apprends de mon choix d’aujourd’hui ? Ai-je une prise de conscience ? Suis-je attentif.ve à mes véritables besoins et mes limites ? Vais-je en prendre soin ? Suis-je prêt.e à lâcher ce qui ne me convient plus, à m’engager dans un changement durable ?";

const paragraphe13 =
    "Ici & Maintenant, je suis libre de faire un choix, à condition de ne pas être trop entravé par mes croyances, mes blessures, mes expériences, mon histoire, mes sentiments de manque, mon dialogue intérieur de pénurie, mes zones aveugles...\n" +
    "C’est pourquoi il est si important de mettre de la conscience sur qui nous sommes, sur ce que nos actes, nos pensées, nos sentiments et nos paroles disent de nous. Ce n’est qu’ainsi que l’on gagne en liberté et que l’on peut devenir qui l’on est véritablement (et non rester “ce que l’on a fait de nous”).\n\n" +
    "Le meilleur moyen d’avancer sur cette voie de la connaissance de soi est d’entamer un travail thérapeutique. Je le conseille à tous. Nul besoin d’avoir de gros problèmes pour faire une Gestalt thérapie, simplement le désir d’évoluer vers plus de conscience et de liberté.";

const paragraphe14 =
    "L’outil des zones d'influence nous aide à voir où est notre pouvoir d'action pour ne pas gaspiller notre énergie.\n" +
    "Il est en lien avec les concepts d’acceptation, de responsabilité et de choix.\n\n" +
    "Marc Aurèle : “Mon Dieu, donnez-moi la sérénité d'accepter les choses que je ne peux pas changer, le courage de changer celles que je peux changer et la sagesse de distinguer les premières des secondes.”";

const paragraphe15 =
    "• MA zone d’influence personnelle :\n\n" +
    "elle concerne tout ce qui m’appartient : mon corps, mes émotions, mes pensées, mes paroles, mes croyances, mes actes, mes comportements, ma posture, mon état d’esprit, mes valeurs, ma vibration, mon rayonnement... Je PEUX m’engager à modifier ce qui ne me convient plus. C’est dans cette zone que j’ai du pouvoir, des choix nombreux et influents.\n\n" +
    "• Zone d’influence partagée :\n\n" +
    "elle concerne tout ce qui m’appartient dans ma relation à l’autre et à l’environnement : j’ai un pouvoir dessus, mais il n’est pas total, il est partagé et je dois accepter et tenir compte de cette relation, de cet autre, de cet environnement.\n" +
    "Il est important de savoir que dans un ensemble, une entité constituée de plusieurs éléments, quand 1 élément bouge, l’équilibre général de l’ensemble est modifié, et de ce fait, les autres éléments bougeront aussi pour garder un équilibre. Ainsi, nous sommes responsables de nous-même, de nos choix et nos décisions, nous pouvons avoir une influence sur nous-même, mais sachons que nous aurons une influence sur l’ensemble du groupe dont nous faisons partie (la relation de famille/couple/amitié, l’environnement, la société, le monde).\n" +
    "Ce qui nous ramène à notre responsabilité (cf ce concept plus haut).\n" +
    "La phrase de Gandhi prend ainsi tout son sens : “Sois le changement que tu veux voir dans le monde”.\n\n" +
    "• Zone d’inertie :\n\n" +
    "c’est la zone dans laquelle je ne pourrai rien changer car je ne m’y investis pas : elle ne dépend donc pas du tout de moi.\n" +
    "Je peux choisir de faire passer certains sujets de cette zone à celle de la zone d’influence partagée si par exemple je décide de m’impliquer et de m’engager dans des actions en rapport avec ces sujets. Mais si je fais le choix de rester extérieur, de ne pas m’y impliquer, il serait plus sain que j’accepte les choses de cette zone telles qu’elles sont. C’est le moment de lâcher-prise.";

const paragraphe16 =
    "Lâcher-prise et acceptation sont intimement liés.\n" +
    "Lâcher prise c’est sortir du contrôle, accepter ce qui est et ce qui adviendra, avec attention et confiance : confiance que des opportunités que l’on imagine pas se présenteront, confiance que l’on saura s’y adapter et saisir celles qui nous conviennent... bref, confiance en soi, dans les autres et dans la vie. C’est en lien avec le sentiment et le dialogue intérieur d’abondance.\n\n" +
    "Lâcher prise ce n’est pas démissionner, abandonner, se résigner. C’est au contraire rester présent et réceptif, en état de veille, une veille calme et apaisée. Cela demande un éveil et une curiosité, une capacité d’adaptation, une posture d’ouverture, de disponibilité et d’accueil à ce qui vient.\n\n" +
    "Lâcher-prise, c'est ce que l'on fait après avoir planté une graine en terre : on continue d'en prendre soin, de l'arroser, nourrir le sol, arracher les mauvaises herbes qui pourraient l'étouffer, la protéger du froid, mais on lâche prise sur sa croissance, sur ce qu'elle deviendra. On laisse agir le temps en restant calmement attentif et vigilant. Quand elle commencera à sortir de terre, quand elle poussera, on avisera, on adaptera alors nos soins et nos actions au fur et à mesure de sa croissance.";

const paragraphe17 =
    "Nous avons autour de notre corps une frontière invisible, comme une bulle, qui nous protège et nous isole. Cette frontière est en permanence en contact avec notre environnement (des personnes et des lieux).\n\n" +
    "À l’intérieur de cette frontière est notre zone d’intimité, notre “maison”, notre abri.\n" +
    "Nous avons le choix d’ouvrir plus ou moins notre Frontière Contact en fonction de nos besoins dans l’environnement autour de nous. Avec des amis elle sera plus ouverte, en situation de stress plus fermée. Elle peut aussi avoir un diamètre plus ou moins grand, de façon à mettre plus ou moins de distance entre nous et notre environnement.\n\n" +
    "Visualiser cette Frontière Contact peut être aidant : c’est un outil pour mettre de la conscience sur nos ressentis et nos réactions, et aussi sur notre intention, notre pouvoir d’action, notre liberté et nos choix.";

const paragraphe18 = //accueil et non jugement
    "Dans l’Ici & Maintenant, on peut accueillir :\n\n" +
    "• une émotion (derrière des mots, des comportements ou des sensations...),\n" +
    "• une personne (derrière une apparence, un comportement...),\n" +
    "• une expérience,\n" +
    "• une relation,\n" +
    "• un environnement\n" +
    "• une situation...";

const paragraphe19 =
    "Accueillir c'est ouvrir une porte et laisser entrer ce qui vient, en observateur silencieux, sans chercher à le modifier.\n" +
    "C'est important de savoir à quoi l'on a réellement affaire avant de réfléchir et d'agir, non?\n\n" +
    "Malheureusement, notre jugement nous empêche d'accueillir vraiment.\n" +
    "Il teinte et déforme ce qui vient à nous parce qu'il nous appartient (chacun juge différemment) : il est l'expression de notre interne, et notre interne, qui est issu de notre mémoire, nos expériences, ce sont nos pensées, émotions, croyances, peurs, failles, espoirs, besoins, limites... Or ce qui nous appartient est différent de ce qui appartient à l'autre, à la relation, à l'expérience, à l'environnement.\n\n" +
    "Notre jugement ne laisse pas de place à l'autre tel qu'il vient à nous, tel qu'il est réellement, unique : il rejette, fige, condamne ou cautionne, encense, magnifie... modifie à travers notre prisme personnel.\n" +
    "De même, notre jugement ne laisse pas de place à l'expérience telle qu'elle se présente à nous, telle qu'elle est réellement, unique : il nie, ignore, engloutit, ou interprète avec des lunettes déformantes (positives ou négatives).\n\n" +
    "Cela peut paraître contradictoire de penser qu'un jugement positif nuit à l'accueil sans jugement, et pourtant, un jugement reste un jugement. Si un jour il est positif, ça veut dire qu’un autre jour il pourra être négatif.\n\n" +
    "Notre jugement n'accueille pas ce qui est là, il le transforme instantanément avant même que nous ayons pu vraiment entrer et rester en contact avec ce qui est, dans l'Ici & Maintenant, à chaque instant.\n\n" +
    "Notre jugement ne laisse pas de place à l'Ici & Maintenant parce qu'on juge depuis quelque chose du passé (expérience, croyance...) ou bien en pensant aux conséquences dans l'avenir.\n\n" +
    "Or c'est dans l'ici et maintenant que nous avons du pouvoir : un pouvoir de pensée, de créativité, d'ajustement, d'action.\n" +
    "La “Page Blanche“ peut nous aider à ne pas être dans le jugement.\n\n" +
    "Accueillir sans juger c'est s'arrêter dans l’Ici & Maintenant, voir, s'ouvrir à ce qui est, et le regarder sans condamner, sans repousser, sans fuir, sans nier, sans ignorer, sans trier ou transformer, ni même cautionner, complimenter...\n\n" +
    "On a besoin d'accueillir ce qui se présente sans le juger pour être conscient de la réalité dans l'Ici & Maintenant et pouvoir décider, en conscience, de ce que l'on veut en faire par la suite. Si l'on n'accueille pas la réalité, on partira sur des bases fausses ou déformées et on aura peu de chances d'avoir des comportements adaptés (pensées, actes...).\n\n" +
    "Accueillir sans jugement, c'est accueillir sans pensées jugeantes. Il ne s'agit pas de taire nos jugements mais bien de parvenir, un jour, à désactiver la fonction ''jugement'' ;)\n\n" +
    "L'accueil sans jugement est un préalable à l'acceptation.";

const paragraphe20 =
    "L’acceptation est un concept qui se vit. C’est comme l’état amoureux par exemple, c’est difficile à expliquer et à comprendre avec le mental: on le découvre et on le reconnaît en l’expérimentant, en le ressentant dans notre corps.\n" +
    "Ne cherchez donc pas à tout comprendre dans ce qui va suivre.\n\n" +
    "Pour être certaine que nous parlons bien de la même chose, je vais d’abord clarifier ce que n’est PAS l’acceptation, afin d’éviter les confusions et pour que parlions du même sujet. Ensuite je développerai ce qu’est l’acceptation et ce qu’elle nous apporte.\n" +
    "L’acceptation est un concept qui pourrait faire l’objet d’un livre entier tellement il est riche. Ce que j’aborde ici est donc loin d’être exhaustif.";

const paragraphe21 =
    "Accepter n’est PAS :\n\n" +
    "• nier : ignorer, refouler, fermer les yeux, faire l’autruche quand on ne veut/peut pas faire face,\n" +
    "• se résigner : subir, sentiment de ne pas avoir le choix, d’être coincé, démotivé, victime,\n" +
    "• cautionner : être d’accord, approuver, valider, juger favorablement.\n\n" +
    "• Nier le réel supprime notre besoin d'agir ou aboutit à une action inappropriée puisque les bases sur lesquelles s'appuie l'action sont faussées (la réalité n’y étant pas intégrée). C'est une lutte intérieure pour ne pas voir, une opposition émotionnelle contre une réalité donnée.\n\n" +
    "• Se résigner c'est se sentir écrasé, abattu, paralysé, victime, c'est baisser les bras de façon défaitiste, abandonner toute idée d'action. Derrière une apparente façade d'acceptation, intérieurement, on est émotionnellement en lutte et en opposition, on est envahi d'émotions contradictoires qui déforment la réalité et nous décentrent de nous-mêmes.\n\n" +
    "Dans le déni comme dans la résignation, l'action est inexistante, sabotée ou inadaptée, la lutte intérieure est énergivore et pesante, l'état émotionnel est tourmenté et lourd. On est envahi et aveuglé par des émotions qui tournent en boucle et on est mentalement inefficace. Avant de pouvoir accepter une réalité, il faut commencer par accepter nos sentiments face à cette réalité : c'est le principe de la régulation émotionnelle (abordée dans les ateliers “Juste Parent”), parce que les sentiments qui ne sont pas acceptés finissent par s’exprimer autrement.\n\n" +
    "• Cautionner c’est juger favorablement. Nos jugements sont très en lien avec nos croyances, nos besoins, nos limites et nos valeurs. Ils nous sont utiles car ils peuvent orienter nos actions. Si l’on juge une réalité comme étant négative, nous allons pouvoir orienter nos actions de telle sorte qu’une telle réalité ne se reproduise pas. Mais avant d’être capable de penser et d’avoir des actions appropriées, nous aurons besoin d’accepter la réalité de l’ici et maintenant.\n\n" +
    "L’acceptation demande dans un 1er temps de mettre de côté notre jugement moral parce qu’en réalité, un fait est neutre : c'est notre jugement qui en fait quelque chose de positif ou négatif, en fonction de ses conséquences sur nous et notre vie à un instant T, de nos besoins, nos croyances, nos valeurs...\n" +
    "C’est aussi notre capacité d’acceptation et ce que l’on fait d’une réalité donnée qui vont en déterminer la suite (heureuse/positive ou malheureuse/négative).\n" +
    "Bien sûr, ce regard nécessite un grand recul et une grande régulation émotionnelle qui sont difficiles au moment où l’on est trop secoué par une réalité difficile à accepter.\n" +
    "C’est justement le processus d’acceptation qui va permettre petit à petit d’avoir ce “méta-regard“ sur la réalité et de prendre des décisions adaptées qui favoriseront notre résilience. La régulation émotionnelle est le point de départ de ce processus (et fait le sujet de plusieurs ateliers “Juste Parent”).\n\n" +
    "L’acceptation n’empêche pas d’avoir un avis, mais d’être, en même temps, capable de s’en distancier, de ne pas faire corps avec lui et de ne pas lutter en son nom contre la réalité.\n" +
    "Parce que lutter contre une réalité, c’est lutter contre le passé qui a aboutit à cette réalité : c’est vain et épuisant, le passé n’étant pas modifiable.";

const paragraphe22 =
    "Alors qu'est-ce que l'acceptation ? Peut-on décider d'accepter ?\n\n" +
    "• L'acceptation c'est l'accueil sans condition et sans jugement de ce qui est (dans l'ici et maintenant). C'est l'intégration neutre (mentalement, corporellement et émotionnellement) d'une réalité donnée. C’est être capable de considérer cette réalité comme un nouveau point de départ, sans persister à lutter contre le passé qui l’a créée. C’est comme repartir de zéro sans être affecté par le passé mais sans pour autant oublier de quoi il est fait.\n\n" +
    "Cela demande un lâcher-prise et une confiance en soi, en ses ressources et en sa capacité à faire face à cette réalité et une confiance dans la vie en général (l’idée que tout a un sens, même si on ne la voit pas tout de suite ou parfois jamais).\n\n" +
    "Accepter c'est lâcher prise, c'est aller avec le courant, utiliser le courant et non y résister, c’est se dire : “ok, les choses étant ce qu’elles sont, que puis-je faire de cela, à partir de maintenant?”\n\n" +
    "• L’acceptation est une émotion: elle est vécue dans notre corps, ressentie intérieurement. Elle est comme l’amour ou la confiance : elle n’est pas mentale, elle ne se décide pas, ne s'impose pas, ne s’exige pas (même de soi-même). Elle est l’aboutissement d’un processus émotionnel, d’un cheminement intérieur (dont fait partie la régulation émotionnelle, abordée dans les ateliers “Juste Parent”). Ça prend du temps et de l'implication, puis ça vient à un moment donné, quand on est prêt, quand c'est juste : on sent quelque chose qui lâche, de la légèreté, du calme.\n\n" +
    "Le cheminement vers l’acceptation commence avec une ouverture, un choix, un désir, une implication, une intention posée d'aller vers l'acceptation. Cela ne permet pas d’y parvenir instantanément mais entame le travail émotionnel qui mènera à l’acceptation";

const paragraphe23 =
    "Que nous apporte l'acceptation ?\n\n" +
    "• libération émotionnelle : en lâchant nos jugements sur le passé et le présent (ici et maintenant), nous sortons de la lutte, de la culpabilité et des regrets qui nous oppressent, nous écrasent et affaiblissent notre pouvoir d'action, voire nous paralysent. Nous ressentons un calme intérieur, un apaisement émotionnel, une reconnexion à soi et à ses ressources internes, une confiance globale en soi et dans la vie.\n\n" +
    "• énergie créatrice : grâce à la libération émotionnelle que nous apporte l’acceptation, nous sommes capables de lâcher nos luttes internes et de vivre alors un regain d'énergie et d'élan, et ainsi, de retrouver notre pouvoir d'action.\n\n" +
    "• actions justes : l'acceptation permet de poser des actions éclairées, ajustées, efficaces et créatives, en conscience.\n\n" +
    "• pouvoir créateur de notre vie : nous ne pouvons changer que ce que nous avons préalablement accepté. Grâce à l’acceptation, nous sommes capables d'avancer à partir d'une réalité pleinement intégrée, de modifier ce qui ne nous convient pas, de bâtir du nouveau à partir de l'existant et de transformer notre avenir de façon libre et dynamique. Nous pouvons prendre conscience de notre responsabilité (sans culpabilité) dans notre vie, et ainsi de reprendre le pouvoir sur notre avenir, le pouvoir d'agir pour éviter d'accumuler plus tard, de nouveaux regrets et de nouvelles culpabilités.";

const paragraphe24 = //Cycle du contact de Zincker
    "Toute expérience peut être observée via le prisme du cycle du contact de Zincker dans lequel on retrouve différentes phases :\n\n" +
    "• la sensation corporelle, physique, interne,\n" +
    "• la prise de conscience de cette sensation/émotion et de sa signification (besoin),\n" +
    "• la mobilisation de l’énergie nécessaire pour répondre à ce besoin,\n" +
    "• l’action qui va permettre de répondre à ce besoin,\n" +
    "• le pré-contact : arrêt juste avant la satisfaction du besoin,\n" +
    "• le contact : l’expérience elle-même de satisfaction du besoin,\n" +
    "• le post-contact : assimilation, relecture, satisfaction, gratitude.\n\n" +
    "• état de retrait avant et après chaque cycle.";

const paragraphe25 = 
    "À chaque étape, on peut observer les concepts engagés dans le processus :\n" +
    "la sensation existe dans l’Ici et Maintenant et demande à être accueillie pour venir à notre conscience fluidement.\n" +
    "sans acceptation de notre sensation et de sa signification (besoin), nous ne passerons pas à l’étape suivante, celle de nous mettre en mouvement pour satisfaire notre besoin.\n" +
    "la mobilisation de notre énergie et nos actions pour satisfaire notre besoin sont en lien avec notre responsabilité, notre liberté de choix, notre intentionnalité et nos zones d’influence.\n" +
    "la qualité du pré-contact, de l’expérience elle-même (contact) et du post-contact dépend de l’ouverture de notre Frontière Contact et de ce que l’on choisit de mettre en figure dans l’Ici et Maintenant.\n" +
    "le post-contact dépend aussi de notre état d’esprit intérieur, de nos croyances, de notre intentionnalité...\n" +
    "les états de retrait sont des moments de lâcher-prise ou de mise dans le “fond” (et non en “figure”).\n\n" +
    "Un exemple concret et simple : \n" +
    "sensation: mon estomac gargouille\n" +
    "prise de conscience : “j’ai faim”\n" +
    "mobilisation de mon énergie : “qu’est-ce que je prépare à manger ? dois-je faire des courses ?...”\n" +
    "action : cuisiner ou faire des courses\n" +
    "le pré-contact : se mettre à table, se servir, saliver, observer et sentir le contenu de son assiette, s’en réjouir\n" +
    "le contact : manger en pleine conscience, prendre le temps de savourer, \n" +
    "le post-contact : ressentir la satisfaction, le plaisir, la gratitude pour ce bon.\n" +
    "retrait : arrêter de manger ou de penser à manger en attendant le prochain cycle de faim.\n\n" +
    "Ce qui peut être intéressant de regarder, ce sont les ruptures/coupures dans notre cycle du contact : voir où elles se situent, dans quelles situations/domaines, si elles sont fréquentes...\n" +
    "Cela parle de nous et peut nous amener à des prises de conscience et à des choix d’ajustements créateurs."

const paragraphe26 = 
    "Si certains de ces concepts vous parlent, si d’autres vous intriguent ou vous interpellent, si vous avez envie d’approfondir ces sujets dans le cadre de votre parentalité, venez participer aux Ateliers “Juste Parent” !\n" +
    "Ce ne sont pas des ateliers de Gestalt (ni thérapie, ni philosophie), mais la parentalité y est abordée avec ma couleur Gestaltiste. Je précise que cela m’appartient et que je n’impose à personne d’adhérer à ma philosophie de vie Gestaltiste ni à toutes mes valeurs. Il est bien entendu important que l’on en partage certaines, telles le respect, l’ouverture, la bienveillance, l’authenticité, l’engagement et la responsabilité.  \n" +
    "Chaque parent a ses propres valeurs, limites et objectifs. C’est pourquoi ces ateliers ne dirigent pas les parents sur le fond mais proposent la forme, le moyen, la posture et les compétences qui vont :\n" +
    "nourrir la relation parent/enfant, \n" +
    "soutenir une communication fluide au quotidien, même dans les désaccords, et\n" +
    "renforcer la sécurité intérieure et l’estime de soi de l’enfant,\n" +
    "tout en respectant les besoins du parent."
export default {
    paragraphe1,
    paragraphe2,
    paragraphe3,
    paragraphe4,
    paragraphe5,
    paragraphe6,
    paragraphe7,
    paragraphe8,
    paragraphe9,
    paragraphe10,
    paragraphe11,
    paragraphe12,
    paragraphe13,
    paragraphe14,
    paragraphe15,
    paragraphe16,
    paragraphe17,
    paragraphe18,
    paragraphe19,
    paragraphe20,
    paragraphe21,
    paragraphe22,
    paragraphe23,
    paragraphe24,
    paragraphe25,
    paragraphe26
}
