const paragraphe1 = "Les Ateliers “Juste Parent”, c’est un pack de 11 ateliers de parents.\n" + 
                     "Découvrez le détail des 11 thèmes abordés\n" +
                     "et en fin de page, les informations plus générales.\n"

const titre2 = "Comment réguler les fortes émotions de nos enfants ?";
const paragraphe2 = "Votre enfant est en proie à des émotions intenses : crises de larmes, de colère,  de frustration ?\n" +
                    "Découvrez comment vous pouvez les calmer rapidement : la régulation émotionnelle.";
const atelier2 = "1er Atelier : je régule ses émotions"



const titre3 = "Pourquoi est-ce essentiel que nous, parents, nous prenions soin de nous ?"
const paragraphe3 = "Pourquoi est-ce indispensable pour nous, parents, mais aussi pour le bien-être de nos enfants ?\n" +
                    "Pourquoi est-ce éducatif et devrait être intégré à toute parentalité ?\n" +
                    "Au cours de ces 11 ateliers, une place et une attention seront données au bien-être des parents.";
const atelier3 = "2e Atelier : je prends soin de moi"



const titre4 = "Le cadre parental : de quoi parle-t-on ? Pourquoi est-il si important ? Comment le définir ?"
const paragraphe4 = "Le cadre parental a un rôle bien précis dans le bien-être de toute la famille.\n" +
                    "Il sécurise un espace de liberté et donne une direction.\n" +
                    "Il est défini par chaque famille en fonction de ses valeurs et de ses besoins spécifiques.\n" +
                    "Pour être compris et entendu, il doit avoir certaines caractéristiques.";
const atelier4 = "3e Atelier : je définis mon cadre parental"


const titre5 = "Comment poser des limites et susciter la coopération de nos enfants ?"
const paragraphe5 = "Quand et comment poser son cadre parental ? \n" +
                "Comment trouver la juste posture parentale et fixer des limites ?\n" +
                   "Qu’est-ce qui suscite la coopération de nos enfants et évite les tensions ?";
const atelier5 = "4e Atelier : je pose mon cadre et suscite sa coopération de façon ferme et bienveillante";

const titre6 = "Comment tenir notre cadre sans blesser ? Comment responsabiliser nos enfants ?";
const paragraphe6 = "Comment communiquer d’une façon bienveillante qui  ne porte pas atteinte à l’estime de soi de nos enfants, même pour exprimer notre mécontentement ?\n" +
                    "Comment veiller au respect du cadre ou guider vers la réparation des erreurs ?";
const atelier6 = "5e Atelier : je tiens mon cadre sans blesser et je responsabilise";

const titre7 = "Comment développer l’autonomie de nos enfants ? Pourquoi c’est si important pour eux ?";
const paragraphe7 = "L’objectif de notre éducation est de favoriser la croissance et l’autonomie de nos enfants.\n" +
                    "Comment pouvons-nous les soutenir efficacement sur ce chemin ?\n" +
                    "Qu’est-ce qui au contraire risque de les freiner ?";
const atelier7 = "6e Atelier : je développe son autonomie et renforce sa confiance en soi";


const titre8 = "Qu’est ce qui valorise nos enfants et nourrit leur estime de soi ?";
const paragraphe8 = "Comment faire des compliments à nos enfants qu’ils pourront vraiment recevoir et s’approprier ?\n" +
                    "Comment émettre des critiques bienveillantes et constructives qui ne blessent pas mais guident ?\n" +
                    "Enfin, comment leur permettre de trouver leur juste place dans la famille ?";
const atelier8 = "7e Atelier : je le/la valorise et nourris son estime de soi";


const titre9 = "En cas de conflit, comment trouver des solutions efficaces et respectueuses de chacun ?";
const paragraphe9 = "Nous vivons des conflits et des crises, que ce soit sur un sujet récurrent ou inédit.\n" +
                    "Comment les résoudre de façon efficace et fluide ?\n" +
                    "Comment trouver des solutions qui, en répondant aux besoins de chacun, seront mieux respectées ?";
const atelier9 = "8e Atelier : la résolution de problèmes / conflits";

const titre10 = "Fratrie : comment favoriser de saines relations entre nos enfants ?";
const paragraphe10 = "En tant que parent, nous avons une grande influence sur l’entente entre nos enfants.\n" +
                    "Qu’est-ce qui favorise une relation saine et harmonieuse entre eux ?\n" +
                    "Qu’est-ce qui les aide à s’épanouir individuellement et à trouver leur juste place dans la fratrie ?";
const atelier10 = "9e Atelier : j’adopte les principes de base pour une bonne entente dans la fratrie";

const titre11 = "Fratrie : comment gérer les disputes ? Comment favoriser l’entente et le respect entre eux ?";
const paragraphe11 = "Que ce soit dans une fratrie ou entre cousins/copains, nos enfants entrent en conflit.\n" +
                    "Comment contribuer à diminuer leurs sentiments de rivalité, de jalousie ou de frustration ?\n" +
                    "Quelle posture pouvons nous avoir pour résoudre durablement leurs conflits et favoriser l’entente, la complicité et une communication respectueuse entre eux ?";
const atelier11 = "10e Atelier : je gère les disputes et les guide vers l’autonomie ";

const titre12 = "Tout au long du chemin : récolte, gratitude, joie, émerveillement";
const paragraphe12 = "Comment entretenir au quotidien un état d’esprit d’abondance, de gratitude, de joie ?\n" + 
                    "Pourquoi est-ce important pour les relations familiales ?\n" +
                    "À long terme, qu’est-ce que ça apporte aux enfants ?";
const atelier12 = "11e Atelier : je récolte, j’assimile, je m’émerveille et j ‘exprime ma gratitude chaque jour";

const outroTitre = "Mais encore ?\n" + 
  "Les ateliers “Juste Parent”, concrètement, ça consiste en quoi ?"
const outroSoustitre = "Ce sont des moments conviviaux en petit groupe (8 personnes maximum)\n" +
                        "dans un cadre chaleureux, bienveillant, confidentiel et non-jugeant.";
const outroParagraphe = "Ils vous offrent une compréhension de ce qu’il se passe dans votre relation parent-enfant,\n" +
                        "vous permettent de clarifier vos besoins, vos priorités et vos objectifs,\n" +
                        "facilitent les prises de conscience nécessaires pour induire le changement que vous désirez,\n" +
                        "vous informent et vous éclairent sur les besoins de vos enfants,\n" +
                        "vous encouragent à exprimer et accepter les vôtres et à prendre soin de vous sans culpabilité,\n" +
                        "vous déculpabilisent quand vous faites face à vos limites,\n" +
                        "vous éveillent à un nouveau mode de communication qui nourrit la relation,\n" +
                        "vous accompagnent dans vos indispensables expérimentations,\n" +
                        "vous guident en cas d’insatisfactions, de “loupés” et de difficultés,\n" +
                        "vous soutiennent tout au long du chemin...\n" +
                        "En résumé, c’est un programme d’enseignement, de réflexion, d’expérimentation et d’accompagnement,\n" +
                        "sur 4 mois, avec un pack de 11 ateliers et 3 accompagnements de groupe.";


export default {
    paragraphe1,
    titre2, paragraphe2, atelier2,
    titre3, paragraphe3, atelier3,
    titre4, paragraphe4, atelier4,
    titre5, paragraphe5, atelier5,
    titre6, paragraphe6, atelier6,
    titre7, paragraphe7, atelier7,
    titre8, paragraphe8, atelier8,
    titre9, paragraphe9, atelier9,
    titre10, paragraphe10, atelier10,
    titre11, paragraphe11, atelier11,
    titre12, paragraphe12, atelier12,
    outroTitre, outroSoustitre, outroParagraphe
};
