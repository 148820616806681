import Article from "../content/components/Article";
import Header from "../content/components/Header";
import LinkButton from "../content/components/LinkButton";
import QuiSuisJeText from "../textes/QuiSuisJeText";

const photo1 = require('../assets/QuiSuisJePage/Photo1.jpg');
const photo2 = require('../assets/QuiSuisJePage/Photo2.jpg');
const photo3 = require('../assets/QuiSuisJePage/Photo3.jpg');

function QuiSuisJePage() {
    return <div style={{width: "100%"}}>
        <Header title="Qui suis-je ?" subtitle={QuiSuisJeText.paragraphe1} />
        {/* Section 1 */}
        <Article color="#EFDCD4">
            <div className="flex flex-row spacing items-center">
                <div className="grow flex flex-col spacing">
                    <h2 className="text-center">Qui suis-je ?</h2>
                    <h5>{QuiSuisJeText.paragraphe2_1}</h5>
                </div>
                <img src={photo1} className="w=1/3 rounded" alt='tkt chef' />
            </div>
            <h5>{QuiSuisJeText.paragraphe2_2}</h5>
        </Article>
        {/* Section 2 */}
        <Article color="#F3E6D8">
            <div className="flex flex-row spacing items-center">
                <img src={photo2} className="w=1/3 rounded" alt='tkt chef' />
                <div className="grow flex flex-col gap-10">
                    <h2>Mon parcours ?</h2>
                    <h5>{QuiSuisJeText.paragraphe3}</h5>
                </div>
            </div>
        </Article>
        {/* Section 3 */}
        <Article color="#DDE0DB">
            <div className="flex flex-col spacing">
                <h2>Mon approche, ma posture</h2>
                <h5>{QuiSuisJeText.paragraphe4}</h5>
                <div className="flex flex-col gap-5 items-center">
                    <LinkButton text="Concepts de Gestalt" path="/gestalt" />
                    <LinkButton text="Ressources" path="/ressources" />
                </div>
                <h5>{QuiSuisJeText.paragraphe5}</h5>
                <h5>{QuiSuisJeText.paragraphe6}</h5>
            </div>
        </Article>
        {/* Section 4 */}
        <Article color="#534F4F">
            <div className="flex flex-row spacing items-center">
                <img src={photo3} className="w=1/3 rounded" alt='tkt chef' />
                <div className="flex flex-col spacing">
                    <h5 className="text-white">{QuiSuisJeText.paragraphe7}</h5>
                    <LinkButton text=">> Je découvre les Ateliers “Juste Parent”" path="/ateliers" />
                </div>
            </div>
        </Article>
    </div>
};


export default QuiSuisJePage;