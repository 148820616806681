import Header from "../content/components/Header";
import LinkButton from "../content/components/LinkButton";
import ContactezMoiText from "../textes/ContactezMoiText";

function ContactezMoiPage() {
    return <div>
        <Header title="Envie d'aller plus loin ?" subtitle={ContactezMoiText.subtitle} />

        <div className="article-div" style={{ backgroundColor: "#DDE0DB" }}>
            <form action="/ma-page-de-traitement" method="post" className="flex flex-col gap-4">
                <h3 className="text-center">Contactez-moi !</h3>
            <h4 className="text-center" style={{ color: "#FF0000" }}>(Fonctionnalité en developpement, vous pouvez m'écrire à justeparent44@gmail.com)</h4>
                <div className="flex flex-row gap-4">
                    <div className="flex flex-row basis-1/2 gap-2">
                        <label htmlFor="name">Prénom*: &emsp;&emsp;&emsp;&emsp;</label>
                        <input className="grow rounded" type="text" id="name" name="user_name" />
                    </div>
                    <div className="flex flex-row basis-1/2 gap-2">
                        <label htmlFor="mail">Nom*: &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;</label>
                        <input className="grow rounded" type="email" id="mail" name="user_mail" />
                    </div>
                </div>
                <div className="flex flex-row gap-4">
                    <div className="flex flex-row basis-1/2 gap-2">
                        <label htmlFor="email">Adresse email*: &emsp;</label>
                        <input className="grow rounded" type="text" id="email" name="user_email" />
                    </div>
                    <div className="flex flex-row basis-1/2 gap-2">
                        <label htmlFor="numero">Numéro de portable: &emsp;</label>
                        <input className="grow rounded" type="email" id="numero" name="user_phone" />
                    </div>
                </div>
                <label htmlFor="how-discover">Comment avez-vous découvert le site Juste Parent 44 ?</label>
                <textarea className="rounded" id="how-discover" name="user_how-discover" />
                <div className="flex flex-row">
                    <p className="basis-1/3">Ma demande concerne: </p>
                    <div className="basis-1/3 flex flex-row gap-2">
                        <input type="checkbox" id="checkbox-atelier" name="interest" value="checkbox-atelier" />
                        <label htmlFor="checkbox-atelier">les Ateliers "Juste Parent"</label>
                    </div>
                    <div className="basis-1/3 flex flex-row gap-2">
                        <input type="checkbox" id="checkbox-accompagnement" name="interest" value="checkbox-accompagnement" />
                        <label htmlFor="checkbox-accompagnement">l'Accompagnement</label>
                    </div>
                </div>
                <label htmlFor="message">Votre message :</label>
                <textarea rows={6} className="rounded" id="message" name="user_message" />
            </form>
        </div>
        <div className="px-8 py-12 flex flex-col gap-5 items-center" style={{ backgroundColor: "#534F4F" }}>
            {/* Footer */}
            <h2 className="text-center" style={{ color: "#EB977C" }}>Mais encore ?</h2>
            <h2 className="text-center" style={{ color: "#FFFFFF" }}>{ContactezMoiText.footer}</h2>
            <LinkButton text="Qui suis-je?" path="/contact" />
            <div className="flex flex-row gap-4 place-items-center">
                <LinkButton text="Je découvre les Ateliers de parents" path="/ateliers" />
                <LinkButton text="Je découvre les Accompagnements" path="/accompagnements" />
            </div>
        </div>
    </div >
}

export default ContactezMoiPage;