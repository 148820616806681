import { ReactNode } from 'react';
import './Article.css';

interface ArticleProps {
    children: ReactNode;
    color: string;
}

function Article({ children, color }: ArticleProps) {
    return <div className='article-div' style={{ backgroundColor: color }}>
        {children}
    </div>
}

export default Article;