import './Header.css';

interface HeaderProps {
    title: string;
    subtitle: string;
}

const logo = require('../../assets/logo.svg');

function Header({ title, subtitle }: HeaderProps) {
    return <div className='header-div'>
        <img src={logo.default} className="max-w-48 max-h-48" alt='tkt chef' style={{ borderRadius: "4px" }} />
        <div className='header flex flex-col items-center'>
            <h1 className='text-center'>{title}</h1>
            <h3 className='text-center whitespace-pre-wrap'>{subtitle}</h3>
        </div>
    </div>
}

export default Header;