import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './NavBar.css';
const logo = require('../../assets/small logo.svg');


function NavBar() {
    const [showNavbar, setShowNavbar] = useState(true);
    const [lastScrollY, setLastScrollY] = useState(0);
    const navigate = useNavigate();

    const handleScroll = () => {
        const currentScrollY = window.scrollY;

        if (currentScrollY > lastScrollY)
            setShowNavbar(false);
        else
            setShowNavbar(true);
        setLastScrollY(currentScrollY);
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [lastScrollY]);

    return <div className={`fixed top-0 left-0 w-full navbar transition-transform duration-300 ${
        showNavbar ? 'transform translate-y-0' : 'transform -translate-y-full'}`}>
        <img src={logo.default} className="w-12 h-12" alt='tkt chef' style={{borderRadius: "4px"}} />

        <button className="navbar-button" onClick={() => {
            navigate('/')
            window.scrollTo(0, 0)
            }}>
            Accueil
        </button>
        <button className="navbar-button" onClick={() => {
            navigate('/ateliers')
            window.scrollTo(0, 0)
            }}>
            Les Ateliers
        </button>
        <button className="navbar-button" onClick={() => {
            navigate('/accompagnements')
            window.scrollTo(0, 0)
            }}>
            L'Accompagnement
        </button>
        <button className="navbar-button" onClick={() => {
            navigate('/qui-suis-je')
            window.scrollTo(0, 0)
            }}>
            Qui suis-je ?
        </button>
        {/* <button className="navbar-button" onClick={() => {
            navigate('/gestalt')
            window.scrollTo(0, 0)
            }}>
            Concepts de Gestalt
        </button> */}
        <button className="navbar-button" onClick={() => {
            navigate('/ressources')
            window.scrollTo(0, 0)
            }}>
            Ressources
        </button>
        <button className="navbar-button" onClick={() => {
            navigate('/tarifs')
            window.scrollTo(0, 0)
            }}>
            Tarifs
        </button>
        <button className="navbar-button" onClick={() => {
            navigate('/contact')
            window.scrollTo(0, 0)
            }}>
            Contactez-moi
        </button>
    </div>
}

export default NavBar;