const paragraphe1 =
    "Je dédie ce site à chaque “Juste Parent” : \n" +
    "qui est “Juste” humain, avec des sentiments, des besoins et des limites\n" +
    "(pas surhumain, infaillible ou invulnérable),\n" +
    "qui recherche une posture parentale “Juste”, équilibrée et équilibrante,\n" +
    "aussi bien pour son/ses enfant/s que pour lui-même. \n" +
    "Et “44” alors ?\n" +
    "Ça se passe à Nantes, en Loire Atlantique ;)";

const paragraphe2 =
    "Vous rêvez d’une relation fluide et harmonieuse avec vos enfants au quotidien ?\n" +
    "Vous voulez les aider à s’épanouir, à développer leurs talents, à grandir en confiance ?\n" +
    "Vous souhaitez les amener progressivement vers l’autonomie, la coopération, la responsabilité ?\n" +
    "Vous aimeriez leur transmettre vos valeurs ?\n" +
    "Et tout ça...sans y laisser votre peau ? Euh pardon, je veux dire, en respectant aussi VOS besoins (et sans aucune culpabilité bien entendu) ?" ;
    

const paragraphe3 =
    "Certains jours, vous imaginez que c’est possible ?\n" +
    "À d’autres moments, vous perdez espoir ?\n" +
    "Les situations conflictuelles répétées ternissent vos relations avec eux et vous épuisent ?\n" +
    "Il vous arrive de perdre patience (puis de le regretter) ?";

const paragraphe4 =
    "Certains jours, vous ne savez plus comment vous y prendre avec eux ?\n" +
    "Vous rêveriez parfois d’avoir un mode d’emploi, un décodeur ou un guide bienveillant (et non jugeant) ?\n" +
    "Vous aimeriez bien comprendre ce qui dérape régulièrement dans votre relation avec vos enfants ?\n" +
    "Il vous arrive de vous sentir seul.e, découragé.e, fatigué.e, démuni.e, perdu.e, usé.e ?\n" +
    "Vous êtes au bon endroit!";

const paragraphe5 =
    "Que vous ayez un enfant ou plusieurs, des petits ou des ados, que vous soyez en couple ou solo, beaux-parents, ou même des grands-parents, vous vous posez des questions sur votre relation avec vos enfants / beaux-enfants / petits-enfants ?\n\n" +

    "Vous vous sentez souvent frustré.e dans vos rapports avec eux ?\n" +
    "Vous doutez parfois de vous et de l’efficacité de vos actions ?\n" +
    "Vous vous sentez seul.e et aimeriez être accompagné.e ou éclairé.e ?\n\n" +

    "Tout votre amour, votre patience et votre énergie ne semblent pas être suffisants pour créer des relations harmonieuses dans votre vie quotidienne mais vous ne savez pas (encore) comment faire autrement ?";

export default { paragraphe1, paragraphe2, paragraphe3, paragraphe4, paragraphe5 }
