const paragraphe1 =
    "Je dédie ce site à tous les parents\n" +
    "qui veulent cheminer vers plus de conscience, d’authenticité et d’équilibre,\n" +
    "qui veulent croire en leur rêve de parentalité et s’en approcher,\n" +
    "qui acceptent d’être accompagnés, guidés et soutenus,\n" +
    "qui se font confiance et ont confiance dans la vie.";

const paragraphe2_1 =
    "Je m'appelle Nathalie, j'ai 54 ans et deux enfants aujourd’hui adultes.\n" +
    "Je suis passée par les doutes, la frustration, la colère, le découragement, la lassitude, la perte de repère...\n" +
    "J’avais souvent un grand sentiment d'impuissance à ne pas parvenir à rendre mes enfants sereins et heureux, et aussi de la culpabilité à ne pas l'être moi-même (alors que, dixit: « j'avais tout pour l'être »).\n" +
    "Pour couronner le tout, j’avais peur d’abîmer mes enfants pour la vie si je ne changeais rien.\n\n" +
    "Mais comment faire autrement ? J’avais besoin d’aide."

const paragraphe2_2 =
    "C'était il y a 13 ans, et depuis, j'en ai fait du chemin pour transformer ma parentalité ! J’y ai gagné une meilleure relation avec mes enfants bien sûr, mais aussi avec moi-même et les autres (mon mari, mes parents, mes amis...) : quel précieux cadeau ! J’ai aussi pris confiance en moi en tant que maman \"suffisamment bonne\", confiance dans le chemin que j’avais choisi, même si je ne faisais pas toujours “tout bien comme il faut”.\n\n" +
    "Mon cheminement n’a pas été de tout repos mais c’est moi qui l’ai choisi et il n’est pas terminé, car c’est pour moi l’aventure d’une vie.";

const paragraphe3 =
    "À partir de 2012, j’ai suivi des ateliers de parents ... deux fois: la première seule, la deuxième fois chez nous, avec mon mari et mes amies!\n" +
    "J’ai aussi participé à des ateliers de CNV et de psychologie positive, puis, je me suis formée pendant 5 ans à la Gestalt et j'ai accompagné des clients en thérapie.\n\n" +
    "Aujourd'hui, j'ai vraiment à cœur de transmettre ce que j'ai reçu parce que ces tout premiers ateliers de parents ont été pour moi une véritable bouée de sauvetage et le point de départ d'un chemin riche de sens et de transformations personnelles, relationnelles et familiales.\n\n" +
    "Je reviens à ma motivation première: celle de redonner de l'espoir et des pistes concrètes aux parents qui en ont besoin. \n" +
    "Parce qu'en tant que parent, nous n'avons pas toujours toutes les clés, mais ce n'est pas une fatalité : il existe des solutions !";

const paragraphe4 = "Mon approche se base sur la Communication NonViolente (CNV) et la Gestalt : ce sont des approches humanistes et des philosophies de vie. Vous trouverez dans les onglets “Concepts de Gestalt” et “Ressources”, un tas d’informations sur ces deux approches.";

const paragraphe5 =
    "Mon rôle est d’accompagner les parents dans leur recherche d’une communication apaisée avec leurs enfants, dans leur désir de créer un climat de confiance et de respect mutuels au quotidien, car ce sont les bases d’une relation profonde, riche, créative et vivante.\n" +
    "Ma posture est bienveillante, non jugeante et encourageante. Je recherche l’intérêt le plus élevé de chaque parent que j’accompagne et je porte l’espoir que chacun.e peut avancer vers plus de sérénité et d’abondance dans sa vie parentale s’il/elle en a le désir.\n" +
    "Mon rôle est aussi, au travers de mes 11 ateliers “Juste Parent”, de les informer pour susciter des prises de conscience, de les initier à de nouvelles habiletés et de nouveaux outils, de les encourager dans leurs expérimentations et tests et de les éclairer pour qu’ils puissent trouver leur propre chemin éducationnel avec leurs propres objectifs et valeurs.\n" +
    "Je suis également là pour les soutenir quand ils fatiguent ou se découragent.";

const paragraphe6 =
    "Les ateliers de parents peuvent apporter un nouveau regard et un nouvel éclairage et peuvent initier un mouvement de transformation des relations familiales pour que chacun puisse s’y déployer et s’y épanouir.\n" +
    "Bien sûr, l’amour est un préalable indispensable à toute relation familiale harmonieuse,  et il ne s’apprend dans aucun stage et ne se commande par aucune volonté. Malheureusement, il n’est pas toujours garant à lui seul de relations sereines au quotidien.\n" +
    "Il est souvent nécessaire de comprendre ce qu’il se passe réellement (intérieurement) dans la relation parent/enfant pour choisir ce que l’on veut modifier et alors, acquérir de nouvelles compétences relationnelles pour se donner les moyens de parvenir aux changements désirés.\n" +
    "Voilà donc certains des objectifs des ateliers “Juste Parent”.";

const paragraphe7 =
    "Je propose les ateliers “Juste Parent” à tous les parents, beaux-parents, grands-parents qui se posent des questions, cherchent des repères et aimeraient être guidés et accompagnés pour apaiser et renforcer leur relation avec leur.s enfant.s.\n" +
    "Ces ateliers ont lieu en petits groupes afin que chacun s’y sente en sécurité pour entamer un cheminement personnel et une transformation durable de sa parentalité.";

export default {
    paragraphe1,
    paragraphe2_1,
    paragraphe2_2,
    paragraphe3,
    paragraphe4,
    paragraphe5,
    paragraphe6,
    paragraphe7,
}
