import Article from "../content/components/Article";
import Header from "../content/components/Header";
import LinkButton from "../content/components/LinkButton";
import AccompagnementsText from "../textes/AccompagnementsText";

const photo1 = require('../assets/AccompagnementsPage/photo1.jpg');
const photo2 = require('../assets/AccompagnementsPage/photo2.jpg');
const photo3 = require('../assets/AccompagnementsPage/photo3.jpg');
const photo4 = require('../assets/AccompagnementsPage/photo4.jpg');

function AccompagnementsPage() {
    return <div style={{width: "100%"}}>
            <Header title="L’ Accompagnement" subtitle={AccompagnementsText.paragraphe1}/>
            {/* Section 1 */}
            <Article color= "#DDE0DB">
                <div className="flex flex-col spacing items-center">
                    <h2 className="text-center">Comment puis-je vous aider ?</h2>
                    <div className="grow flex flex-row spacing">
                        <h5 className="text-left">{AccompagnementsText.paragraphe2}</h5>
                        <img src={photo1} className="w-1/2 rounded" alt='tkt chef' />
                    </div>
                </div>
            </Article>
            {/* Section 2 */}
            <Article color="#F3E6D8">
                <div className="flex flex-row spacing items-center">
                    <img src={photo2} className="w-1/4 rounded" alt='tkt chef'/>
                    <div className="grow flex flex-col spacing">
                        <h2 className="text-center">Pour qui ?</h2>
                        <h5 className="text-left">{AccompagnementsText.paragraphe3}</h5>
                    </div>
                </div>
            </Article>
            {/* Section 3 */}
            <Article color="#FBF6F1">
                <div className="items-center flex flex-col spacing">
                    <h3 className="text-center">{AccompagnementsText.paragraphe4}</h3>
                    <LinkButton text="Qui-suis-je ?" path="/qui-suis-je" />
                </div>
            </Article>
            {/* Section 4 */}
            <Article color= "#534F4F" >
                <div className="items-center flex flex-col spacing">
                    <div className="grow items-center flex flex-row gap-10">
                        <img src={photo3} className="w-1/5 rounded" alt='tkt chef' />
                        <div className="grow items-center flex flex-col spacing">
                        <h2 className="text-center" style={{ color: "#EB977C" }}>Mais encore ?</h2>
                        <h5 className="text-center text-white">{AccompagnementsText.paragraphe5}</h5>
                        </div>
                        <img src={photo4} className="w-1/5 rounded" alt='tkt chef' />
                    </div>
                    <div className="flex flex-row spacing">
                        <div className="flex flex-col w-1/2 pr-5 spacing border-solid border-r-2 border-r-white">
                            <h4 className="text-center text-white">
                                Accompagnement de Groupe :<br />
                                parce que les expériences des uns éclairent aussi les autres.
                            </h4>
                            <h5 className="text-white">{AccompagnementsText.paragraphe6}</h5>
                        </div>
                        <div className="flex flex-col w-1/2 pl-5 spacing">
                            <h4 className="text-center text-white">
                                Accompagnement individuel (ou en couple) :<br />
                                personnalisé, sur mesure, adapté à vos besoins.
                            </h4>
                            <h5 className="text-white">{AccompagnementsText.paragraphe7}</h5>
                        </div>
                    </div>
                    <h5 className="text-white">Pour tout renseignement relatif au coût des différents accompagnements, vous pouvez consulter l’onglet “Tarifs”.</h5>
                    <LinkButton text="Qui suis-je?" path="/qui-suis-je" />
                </div>
            </Article>
        </div >
}

export default AccompagnementsPage;