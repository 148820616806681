import Header from "../content/components/Header";
import LinkButton from "../content/components/LinkButton";
import {ImageLink, WTTImageLink} from "../content/components/RessourceLink";
// import ImageLink from "../content/components/RessourceLink";

import RessourcesText from "../textes/RessourcesText";
const livre1 = require('../assets/RessourcesPage/LIVRE 1.jpg');
const livre2 = require('../assets/RessourcesPage/LIVRE 2.jpg');
const livre3 = require('../assets/RessourcesPage/LIVRE 3.jpg');
const livre4 = require('../assets/RessourcesPage/LIVRE 4.jpg');
const livre5 = require('../assets/RessourcesPage/LIVRE 5.jpg');
const livre6 = require('../assets/RessourcesPage/LIVRE 6.jpg');
const livre7 = require('../assets/RessourcesPage/LIVRE 7.jpg');
const livre8 = require('../assets/RessourcesPage/LIVRE 8.jpg');
const livre9 = require('../assets/RessourcesPage/LIVRE 9.jpg');
const livre10 = require('../assets/RessourcesPage/LIVRE 10.jpg');
const livre11 = require('../assets/RessourcesPage/LIVRE 11.jpg');
const livre12 = require('../assets/RessourcesPage/LIVRE 12.jpg');
const livre13 = require('../assets/RessourcesPage/LIVRE 13.jpg');
const livre14 = require('../assets/RessourcesPage/LIVRE 14.jpg');
const livre15 = require('../assets/RessourcesPage/LIVRE 15.jpg');
const livre16 = require('../assets/RessourcesPage/LIVRE 16.jpg');
const livre17 = require('../assets/RessourcesPage/LIVRE 17.jpg');


const youtube1 = require('../assets/RessourcesPage/YT 1.jpg');
const youtube2 = require('../assets/RessourcesPage/YT 2.png');
const youtube3 = require('../assets/RessourcesPage/YT 3.jpg');

const website1 = require('../assets/RessourcesPage/website 1.png');
const website2 = require('../assets/RessourcesPage/website 2.jpg');
const GestaltLogo = require('../assets/RessourcesPage/Gestalt logo.png');

const link1 = "https://www.librairiedurance.fr/livre/9782954560502-entre-parent-et-enfant-haim-g-ginott/"
const link2 = "https://www.librairiedurance.fr/livre/9782954560526-entre-parent-et-adolescent-ginott-haim-catherine-van-santem/"
const link3 = "https://www.achat.auxeditionsduphare.fr/fr/livres-en-francais/15-parents-epanouis-enfants-epanouis-votre-guide-pour-une-famille-plus-heureuse-9782981161086.html"
const link4 = "https://www.achat.auxeditionsduphare.fr/fr/livres/1-parler-pour-que-les-enfants-ecoutent-ecouter-pour-que-les-enfants-parlent-9782981161062.html"
const link5 = "https://www.achat.auxeditionsduphare.fr/fr/home/22-parler-pour-que-les-ados-ecoutent-ecouter-pour-que-les-ados-parlent-9782981373021.html"
const link6 = "https://www.achat.auxeditionsduphare.fr/fr/livres/9-freres-et-soeurs-sans-rivalit-9782981161017.html"
const link7 = "https://www.achat.auxeditionsduphare.fr/fr/livres/36-etre-le-parent-que-vous-avez-toujours-voulu-etre-9782981373007.html"
const link8 = "https://www.achat.auxeditionsduphare.fr/fr/home/106-parler-pour-que-les-tout-petits-%C3%A9coutent-9782981591258.html"
const link9 = "https://www.achat.auxeditionsduphare.fr/fr/home/150-comment-parler-quand-les-enfants-necoutent-pas-9782982106802.html?adtoken=a604c31880915da1ed088cc5802f192a&ad=admin709kmwgb1&id_employee=1"
const link10 = "https://www.librairiedurance.fr/livre/9782501141314-la-discipline-positive-en-famille-a-l-ecole-comment-eduquer-avec-fermete-et-bienveillance-jane-nelson-beatrice-sabate/"
const link11 = "https://www.librairiedurance.fr/livre/9782501161657-la-discipline-positive-de-3-a-6-ans-eduquer-avec-fermete-et-bienveillance-jane-nelsen-cheryl-erwin-roslyn-ann-duffy/"
const link12 = "https://www.librairiedurance.fr/livre/9782501141321-la-discipline-positive-pour-les-adolescents-jane-nelson/"
const link13 = "https://www.librairiedurance.fr/livre/9782501150712-la-discipline-positive-pour-les-parents-solo-jane-nelsen-cheryl-erwin-carol-delzer/"
const link14 = "https://www.librairiedurance.fr/livre/9782352045502-les-lois-naturelles-de-l-enfant-la-revolution-de-l-education-celine-alvarez/"
const link15 = "https://www.editions-eyrolles.com/livre/la-psychologie-positive-avec-les-enfants"
const link16 = "https://www.librairiedurance.fr/livre/9782761955171-cessez-d-etre-gentil-soyez-vrai-thomas-d-ansembourg/"
const link17 = "https://www.librairiedurance.fr/livre/9782707188793-les-mots-sont-des-fenetres-ou-bien-ce-sont-des-murs-initiation-a-la-communication-nonviolente-marshall-b-rosenberg/"

const linkYT1 = "https://www.youtube.com/@agnesdutheil1140"
const linkYT2 = "https://www.youtube.com/watch?v=Z2kkC3Bc9Qg"
const linkYT3 = "https://www.youtube.com/playlist?list=PLqLwOYx3B4PKa4WqLLXd_k6ZOM_8rD4kI"
const ws1 = "https://www.youtube.com/playlist?list=PLHIsFQ2xlM8DBymDre7yxLfg2FZpM6nUO/"
const ws2 = "https://cnvfrance.fr/communication-non-violente/"

const text = "Lire un livre, regarder une vidéo, glaner des informations sur un site internet, c’est déjà s’ouvrir à une réflexion et envisager des pistes pour trouver des solutions aux difficultés que nous rencontrons. C’est un premier pas.\n\n" +
            "Ce n’est pas suffisant pour vraiment créer un changement durable dans notre quotidien avec nos enfants. Nous avons besoin pour cela : \n" +
            "de modifier notre regard et notre perception, de prendre conscience de ce qui coince dans notre relation avec nos enfants, de clarifier nos vrais besoins, nos valeurs et nos objectifs, d’accepter nos limites sans culpabilité, d’apprendre à modifier notre posture et notre mode de communication... \n\n" +
            "Selon mon expérience, tout cela nécessite un accompagnement, un regard extérieur, du soutien, de l’encouragement, un apprentissage et de nouvelles expérimentations avec des retours pour éclairer et permettre des ajustements.\n\n" +
            "C’est tout l’intérêt des Ateliers “Juste Parent” :  des ateliers de parents en petit groupe dans une atmosphère chaleureuse et non jugeante qui offrent un environnement sécure pour amorcer puis pérenniser une réelle transformation de notre posture parentale et de nos rapports avec nos enfants."

function RessourcesPage() {
    return <div style={{width: "100%"}}>
        <Header title="Ressources" subtitle={RessourcesText.intro}/>
        
        <div className="flex flex-col p-32" style={{backgroundColor: "#DDE0DB"}} >
            <h3 className="text-center font-bold">À propos de la Parentalité</h3>
            <div className="grow flex flex-col p-8 ">
                <h2 className="text-left">Livres</h2>
                {/* Section 1 */}
                <div className='article-div'>
                    <div className="flex flex-col spacing-ressources items-center">
                        <h3>de Haim Ginott</h3>
                        <div className="flex flex-row spacing-ressources items-center">
                            <ImageLink src={livre1} path={link1}/>
                            <ImageLink src={livre2} path={link2}/>
                        </div>
                    </div>
                </div>
                <div className='article-div'>
                    <div className="flex flex-col spacing-ressources items-center">
                        <h3>d’Adèle FABER et Elaine MAZLISH :</h3>
                        <div className="flex flex-row spacing-ressources items-center">
                            <ImageLink src={livre3} path={link3}/>
                            <ImageLink src={livre4} path={link4}/>
                            <ImageLink src={livre5} path={link5}/>
                        </div>
                        <div className="flex flex-row spacing-ressources items-center">
                            <ImageLink src={livre6} path={link6}/>
                            <ImageLink src={livre7} path={link7}/>
                        </div>
                    </div>
                </div>
                <div className='article-div'>
                    <div className="flex flex-col spacing-ressources items-center">
                        <h3>de Joanna Faber & Julie King</h3>
                        <div className="flex flex-row spacing-ressources items-center">
                            <ImageLink src={livre8} path={link8}/>
                            <ImageLink src={livre9} path={link9}/>
                        </div>
                    </div>
                </div>
                <div className='article-div'>
                    <div className="flex flex-col spacing-ressources items-center">
                        <h3>de Jane NELSEN :</h3>
                        <div className="flex flex-row spacing-ressources items-center">
                            <ImageLink src={livre10} path={link10}/>
                            <ImageLink src={livre11} path={link11}/>
                        </div>
                        <div className="flex flex-row spacing-ressources items-center">
                            <ImageLink src={livre12} path={link12}/>
                            <ImageLink src={livre13} path={link13}/>
                        </div>
                    </div>
                </div>
                <div className='article-div'>
                    <div className="flex flex-col spacing-ressources items-center">
                        <h3>de Céline Alvarez</h3>
                        <div className="flex flex-row spacing-ressources items-center">
                            <div/>
                            <div/>
                            <ImageLink src={livre14} path={link14}/>
                            <div/>
                            <div/>
                        </div>
                    </div>
                </div>
                <div className='article-div'>
                    <div className="flex flex-col spacing-ressources items-center">
                        <h3>d’Agnès Dutheil</h3>
                        <div className="flex flex-row spacing-ressources items-center">
                            <div/>
                            <div/>
                            <ImageLink src={livre15} path={link15}/>
                            <div/>
                            <div/>
                        </div>
                    </div>
                    <div className="flex flex-col spacing-ressources items-center pt-20">
                        <h3>d’Agnès Dutheil</h3>
                        <div className="flex flex-col grow items-center">
                            <img
                                onClick={() => window.open(linkYT1, '_blank')?.focus()}
                                src={youtube1}
                                className="rounded-md hover:cursor-pointer hover:shadow-2xl grow aspect-square"
                                alt='tkt chef'
                                />
                            <h5>Youtube</h5>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="flex flex-col p-32" style={{backgroundColor: "#F3E8E8"}} >
            <h3 className="text-center font-bold">À propos de la CNV (Communication NonViolente)</h3>
            <div className="grow flex flex-col p-8 ">
                <h2 className="text-left">DOCUMENTS</h2>
                <div className='flex flex-col py-12 gap-5'>
                    <div className="flex flex-row spacing-ressources items-center ">
                        <h5 className="max-w-xs text-left"> une liste des émotions (non exhaustive) : </h5>
                        <a
                            style={{
                                fontFamily: "Afacad, sans-serif",
                                fontWeight: 400,
                                fontSize: "35px",
                                fontStyle: "normal",
                                lineHeight: "40px",
                                whiteSpace: "pre-wrap",
                            }}
                            href="https://cnvformations.fr/wp-content/uploads/2022/04/Outil-pedagogique-CNV_Liste-des-sentiments.pdf"
                            target="_blank"
                            className="underline text-left text-1xl text-shantell-sans"
                        >
                            https://cnvformations.fr/wp-content/uploads/2022/04/Outil-pedagogique-CNV_Liste-des-sentiments.pdf
                        </a>
                    </div>
                    <div className="flex flex-row spacing-ressources items-center">
                        <h5 className="max-w-xs text-left"> une liste des besoins (non exhaustive) : </h5>
                        <a
                            style={{
                                fontFamily: "Afacad, sans-serif",
                                fontWeight: 400,
                                fontSize: "35px",
                                fontStyle: "normal",
                                lineHeight: "40px",
                                whiteSpace: "pre-wrap",
                            }}
                            href="https://cnvformations.fr/wp-content/uploads/2022/04/Outil-pedagogique-CNV_Liste-des-besoins.pdf"
                            target="_blank"
                            className="underline text-left text-1xl text-shantell-sans"
                        >
                            https://cnvformations.fr/wp-content/uploads/2022/04/Outil-pedagogique-CNV_Liste-des-besoins.pdf
                        </a>
                    </div>
                    <div className="flex flex-row spacing-ressources items-center">
                    <h5 className="max-w-xs text-left"> une liste des valeurs (non exhaustive) : </h5>
                        <a
                            style={{
                                fontFamily: "Afacad, sans-serif",
                                fontWeight: 400,
                                fontSize: "35px",
                                fontStyle: "normal",
                                lineHeight: "40px",
                                whiteSpace: "pre-wrap",
                            }}
                            href="https://www.lapsychologiepositive.fr/liste-des-valeurs/"
                            target="_blank"
                            className="underline text-left text-1xl text-shantell-sans"
                        >
                            https://www.lapsychologiepositive.fr/liste-des-valeurs/
                        </a>
                    </div>
                </div>
            </div>
            <div className="grow flex flex-col p-8 ">
            <h2 className="text-left">LIVRES</h2>
                <div className='article-div'>
                    <div className="flex flex-col spacing-ressources items-center">
                        <h3>de Thomas d’Ansembourg</h3>
                        <div className="flex flex-row spacing-ressources items-center">
                            <div></div>
                            <div></div>
                            <ImageLink src={livre16} path={link16}/>
                            <div></div>
                            <div></div>
                        </div>
                    </div>
                </div>
                <div className='article-div'>
                    <div className="flex flex-col spacing-ressources items-center">
                    <h3>de Marshall Rosenberg (père de la CNV)</h3>
                        <div className="flex flex-row spacing-ressources items-center">
                            <div></div>
                            <div></div>
                            <ImageLink src={livre17} path={link17}/>
                            <div></div>
                            <div></div>
                        </div>
                    </div>
                </div>
                <h2 className="text-left">VIDÉOS YOUTUBE</h2>
                <div className='article-div'>
                    <div className="flex flex-col spacing-ressources items-center">
                        <div className="flex flex-row spacing-ressources items-end">
                            <div className="flex flex-col spacing-ressources items-center">
                                <WTTImageLink src={youtube2} path={linkYT2}/>
                                <h5>Thomas d’Ansembourg</h5>
                            </div>
                            <div className="flex flex-col spacing-ressources items-center">
                                <ImageLink src={youtube3} path={linkYT3}/>
                                <h5>Marshall Rosenberg</h5>
                            </div>
                        </div>
                    </div>
                </div>
                <h2 className="text-left">SITES INTERNET</h2>
                <div className='article-div'>
                    <div className="flex flex-col spacing-ressources items-center">
                        <div className="flex flex-row spacing-ressources items-end">
                            <div className="flex flex-col spacing-ressources items-center">
                                <ImageLink src={website1} path={ws1}/>
                                <h5>Thomas d’Ansembourg</h5>
                            </div>
                            <div className="flex flex-col spacing-ressources items-center">
                                <ImageLink src={website2} path={ws2}/>
                                <h5>CNV France</h5>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="grow flex flex-col p-8" style={{backgroundColor: "#F7EDDE"}}>
            <h3 className="text-center font-bold">À propos de la Gestalt,
            à la fois une thérapie et une philosophie de vie.</h3>
            {/* Section 1 */}
            <div className="grow flex flex-row spacing-ressources items-center">
                <div className='article-div'>
                    <div className="flex flex-row spacing-ressources">
                        <div className="flex flex-col spacing-ressources">
                            <h2 className="text-left">SITE INTERNET</h2>
                            <div className="flex flex-row spacing-ressources">
                                <h5>École Humaniste de Gestalt : </h5>
                                <a
                                    style={{
                                        fontFamily: "Afacad, sans-serif",
                                        fontWeight: 400,
                                        fontSize: "35px",
                                        fontStyle: "normal",
                                        lineHeight: "40px",
                                        whiteSpace: "pre-wrap",
                                    }}
                                    href="https://gestalt.fr/gestalt-therapie/"
                                    target="_blank"
                                    className="underline text-left text-1xl text-shantell-sans"
                                >
                                    https://gestalt.fr/gestalt-therapie/
                                </a>
                            </div>
                            <h2 className="text-left">VIDÉOS YOUTUBE</h2>
                            <div className="flex flex-col spacing-ressources">
                                <a
                                    style={{
                                        fontFamily: "Afacad, sans-serif",
                                        fontWeight: 400,
                                        fontSize: "35px",
                                        fontStyle: "normal",
                                        lineHeight: "40px",
                                        whiteSpace: "pre-wrap",
                                    }}
                                    href="https://www.youtube.com/@LECOLEHUMANISTEDEGESTALT"
                                    target="_blank"
                                    className="underline text-left text-1xl text-shantell-sans"
                                >
                                    https://www.youtube.com/@LECOLEHUMANISTEDEGESTALT
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <img src={GestaltLogo} className="white-to-transparent aspect-square max-w-48 rounded-md hover:cursor-pointer hover:shadow-2xl" alt='tkt chef'/>
            </div>
            <div className="flex w-auto justify-center pb-16">
                <LinkButton text="Découvrez ma page Concepts (et outils) de Gestalt" path="/gestalt" />
            </div>
        </div>
        <div className="flex flex-col spacing" style={{backgroundColor: "#534F4F"}} >
            <div className='article-div'>
                <div className="flex flex-col spacing items-center">
                    <h2 className="text-center" style={{ color: "#EB977C" }}>Mais encore ?</h2>
                    <h5 style={{ color: "#FFFFFF" }}>{text}</h5>
                    <div className="flex flex-row spacing justify-between">
                    <LinkButton text="Découvrez les Ateliers “Juste Parent“" path="/ateliers" />
                    <LinkButton text="Découvrez mes Accompagnements" path="/accompagnements" />
                    </div>
                </div>
            </div>
        </div>
    </div>
}

export default RessourcesPage;
