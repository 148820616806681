const paragraphe1 =
    "Vous ressentez le besoin d’être accompagné.e / guidé.e\n" +
    "pour transformer votre parentalité ?\n" +
    "Découvrez ici les accompagnements que je vous propose.";

const paragraphe2 =
        "Vous voulez apaiser votre communication et diminuer les tensions relationnelles avec vos enfants tout en respectant vos choix éducatifs, et vous avez besoin de soutien ?\n\n" +
        "Je vous apporte mon éclairage sur les situations qui créent des tensions entre vous.\n" +
        "Je vous guide pour que vous puissiez trouver les solutions qui résoudront vos différends.\n" +
        "Je vous encourage dans vos indispensables expérimentations proposées pendant les ateliers “Juste Parent”.\n\n" +
        "Ma posture est soutenante, bienveillante et non jugeante.\n" +
        "Je m’engage à mettre mes compétences, mon expérience, et mon cœur à vous accompagner sur votre chemin de transformation.\n\n" +
        "Avec mon soutien et votre motivation, votre entrain et votre confiance, vous pourrez durablement transformer votre relation avec vos enfants !"

const paragraphe3 =
    "J’accompagne exclusivement les personnes qui suivent (ou ont suivi) les 11 ateliers de parents.\n\n" +
    "Pourquoi ?\n\n" +
    "Si vous voulez que mes accompagnements vous soient durablement utiles et efficaces, vous aurez besoin de connaître les bases que je présente au cours de ces ateliers.\n\n" +
    "Or, ces ateliers vous apportent plus de 35 heures de contenus et d’accompagnements. \n" +
    "Il faudrait 47 séances d’accompagnement individuel de 45 minutes pour recevoir toutes ces informations : ce serait déraisonnable en termes d’efficacité et de prix !";

const paragraphe4 =
    "Pour que les ateliers de parents vous soient profitables dans la durée,\n" +
    "un accompagnement est, selon moi, indispensable. Sinon, le contenu reste théorique.\n" +
    "C’est pourquoi, dans mon programme des Ateliers “Juste Parent” sur 4 mois,\n" +
    "je propose 3 séances d’accompagnement de groupe (en plus des 11 ateliers).";

const paragraphe5 =
    "Vous pouvez réserver des séances d’accompagnement supplémentaires tout au long de la formation et/ou après avoir terminé les 11 ateliers.\n\n" +
    "Vous avez 2 formats d’accompagnement au choix";

const paragraphe6 = //celui de gauche
    "Au cours d’un accompagnement de groupe, chaque participant.e vient déposer ce qui lui pèse dans sa parentalité, partage une difficulté relationnelle avec son/ses enfant(s) ou un défi éducatif à relever ou toute autre question en lien avec sa parentalité.\n\n" +
    "Je l’écoute attentivement, lui apporte mon soutien et mon éclairage, lui suggère des pistes de réflexion, des actions possibles...\n\n" +
    "Les autres participants accueillent avec bienveillance ce partage, sans le juger ni le commenter.\n\n" +
    "C’est puissant de se sentir ainsi entendu.e et soutenu.e par tout un groupe.\n\n" +
    "C’est libérateur d’oser partager ses difficultés devant les autres participants.\n\n" +
    "C’est rassurant de constater que l’on n’est pas seul.e à “galérer”.\n\n" +
    "C’est enrichissant d’entendre l’accompagnement des autres.\n\n" +
    "C’est motivant de sentir que l’on avance tous ensemble sur un même chemin.\n\n" +
    "Afin que chacun puisse se dévoiler en sécurité dans le groupe, tous les participants s’engagent à respecter la clause de confidentialité que je pose dans le cadre.\n\n" +
    "La durée d’un accompagnement de groupe varie en fonction du nombre de participants. Il faut compter environ 15 minutes par participant, et un minimum de 4 participants.";

const paragraphe7 = //celui de droite
    "Au cours d’un accompagnement individuel, vous venez déposer ce qui vous pèse dans votre parentalité, me partagez une difficulté relationnelle avec votre/vos enfant(s) ou un défi éducatif à relever ou toute autre question en lien avec votre parentalité.\n\n" +
    "Je vous écoute attentivement, vous apporte mon soutien et mon éclairage, vous suggère des pistes de réflexion, des actions possibles...\n\n" +
    "L’accompagnement individuel vous permet d’aborder des sujets délicats que vous ne souhaitez pas aborder devant un groupe, de vous livrer sans crainte du regard des autres et d’être ainsi totalement authentique et de résoudre des difficultés particulières.\n\n" +
    "Je suis entièrement disponible pour vous pendant cet accompagnement, dans la bienveillance et le non-jugement, et je respecte bien entendu la confidentialité de ce que vous me dévoilez.\n\n" +
    "La durée d’un accompagnement individuel est de 45 minutes.";

const paragraphe8 = "Pour tout renseignement relatif au coût des différents accompagnements, vous pouvez consulter l’onglet “Tarifs”.";

export default { paragraphe1, paragraphe2, paragraphe3, paragraphe4, paragraphe5, paragraphe6, paragraphe7, paragraphe8 };
