import '../../TextStyle.css';

interface RessourceLinkProps {
    path: string;
    text?: string;
}
interface ImageLinkProps {
    path: string;
    src: any;
}

function RessourceLink({ path, text }: RessourceLinkProps) {
    const toto = text ? text : path;
    return <a href={path} target="_blank" className="underline text-left text-1xl text-shantell-sans">{toto}</a>
}

function ImageLink({ path, src }: ImageLinkProps) {
    return <img
        onClick={() => window.open(path, '_blank')?.focus()}
        src={src}
        className="w-1/2 rounded-md hover:cursor-pointer hover:shadow-2xl"
        alt='tkt chef'
    />
}

function WTTImageLink({ path, src }: ImageLinkProps) {
    return <img
        onClick={() => window.open(path, '_blank')?.focus()}
        src={src}
        className="white-to-transparent w-1/2 rounded-md hover:cursor-pointer hover:shadow-2xl"
        alt='tkt chef'
    />
}

export { RessourceLink, ImageLink, WTTImageLink };
