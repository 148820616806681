import { useNavigate } from 'react-router-dom';
import './LinkButton.css';

interface ButtonProps {
    text: string;
    path: string;
}

function LinkButton({ text, path }: ButtonProps) {
    const textToDisplay = `>> ${text}`;
    const navigate = useNavigate();
    return <button className='link-button rounded-full font-bold text-Itim'
    onClick={() => {
                navigate(path)
                window.scrollTo(0, 0)
            }}>
        <p className='link-button-text-style'>

            {textToDisplay}
        </p>
    </button>
}

export default LinkButton;