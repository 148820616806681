import Article from "../content/components/Article";
import Header from "../content/components/Header";
import LinkButton from "../content/components/LinkButton";
import HomeText from "../textes/HomeText";

import '../TextStyle.css';

const photo1 = require('../assets/HomePage/photo1.jpg');
const photo2 = require('../assets/HomePage/photo2.jpg');
const photo3 = require('../assets/HomePage/photo3.jpg');
const photo4 = require('../assets/HomePage/photo4.jpg');

function HomePage() {
    return <div style={{ width: "100%" }}>
        <Header title='Bienvenue sur Juste Parent 44 !' subtitle={HomeText.paragraphe1} />

        <Article color="#CAD0C8">
            <div className="flex flex-row spacing items-center pb-10">
                <h5>{HomeText.paragraphe2}</h5>
                <img src={photo1} className="w-1/3" alt='tkt chef' style={{ borderRadius: "4px" }} />
            </div>
            <div className="flex flex-row spacing items-center">
                <img src={photo2} className="w-1/2" alt='tkt chef' style={{ borderRadius: "4px" }} />
                <h5>{HomeText.paragraphe3}</h5>
            </div>
        </Article>

        <Article color="#FBF6F1">
            <div className="flex flex-col spacing items-center">
                <h3 className="text-center">{HomeText.paragraphe4}</h3>
                <LinkButton text="Je découvre les Ateliers “Juste Parent“" path="/ateliers" />
            </div>
        </Article>

        <Article color="#534F4F">
            <div className="flex flex-row spacing items-center">
                <img src={photo3} className="w-60" alt='tkt chef' style={{ borderRadius: "4px" }} />
                <h5 className="text-center text-white">{HomeText.paragraphe5}</h5>
                <img src={photo4} className="w-60" alt='tkt chef' style={{ borderRadius: "4px" }} />
            </div>
        </Article>
    </div >
}

export default HomePage;
